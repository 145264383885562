import React from "react";
import { ConfigProvider } from "antd";
import ptBR from "antd/es/locale/pt_BR";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { isAuthenticated } from './services/auth';

import "./App.less";

import { ConsultaPecas, Home, Login, SelecionarCliente } from "./pages";

export default function App() {
  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );

  return (


    <ConfigProvider locale={ptBR} componentSize="small">
      <Router>
        <Switch>
          <Route exact path="/" component={Login}/>
          <PrivateRoute path="/home" component={Home} />
          <PrivateRoute path="/selecionarCliente" component={SelecionarCliente} />
          {/* <Route exact path="/ativar-novo-email" component={AtivarNovoEmail}/> */}
        </Switch>
      </Router>
    </ConfigProvider>

  );

}