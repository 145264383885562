import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Layout, Avatar, Dropdown, Menu, Tooltip, Button, Drawer, Input, Typography, Badge,Select } from "antd";
import { SearchOutlined, UserOutlined, SettingFilled, DeleteOutlined, LogoutOutlined, LockOutlined,AppstoreOutlined, BellFilled, SaveFilled, InfoCircleTwoTone, CheckOutlined, DesktopOutlined, ExpandOutlined, WechatFilled, PrinterFilled,ShoppingCartOutlined  } from '@ant-design/icons';

import { logout,setClienteSelecionado } from '../../services/auth';
import "./index.less";
import api from '../../services/api';
import logo from '../../assets/logoGestor.png';
import { ModalEmpresaFilial } from "../../components/modals/modalPages";
import { ModulosPage, Perfil } from "../../components";
import { getUserPerm } from '../../services/auth';


export default function HeaderPage() {

    const [openPerfil, setOpenPerfil] = useState(false);
    const [openBuscaGlobal, setOpenBuscaGlobal] = useState(false);
    const [openModalModulos, setOpenModalModulos] = useState(false);
    const [openModalEmpresaFilial, setOpenModalEmpresaFilial] = useState(false);
    const [empresaCod, setEmpresaCod] = useState(getUserPerm().emp_id);
    const [localCod, setLocalCod] = useState(getUserPerm().loc_id);
    const [nomeUsu, setNomeUsu] = useState(getUserPerm().usu_sname);
    const [codVendLogado, setCodVendLogado] = useState(getUserPerm().ven_id);
    const [codCliLogado, setCodCliLogado] = useState(getUserPerm().cli_id);
    const [nomeEmpresa, setNomeEmpresa] = useState("");
    const [nomeLocal, setNomeLocal] = useState("");
    const [pesquisa, setPesquisa] = useState("");
    const { Option, OptGroup } = Select;

    const [ listarClientes, setListaClientes ] = useState([]);

    useEffect(() => carregaDados(), [empresaCod, localCod]);
    useEffect(() => carregarDadosCli(), [codVendLogado,codCliLogado]);

    function carregarDadosCli() {
        setListaClientes([]);
        if(codVendLogado>0)
        {
            api.get('/Clientes/GetAllVendedor?codeVend='+codVendLogado).then(res => {
                if (res.status === 200) {
                    setListaClientes(res.data);
                }
            })
            .catch((erro) => console.error(erro));
        }else if (codCliLogado === 0)
        {
            api.get('/Clientes/GetAllHeader?filtro'+pesquisa).then(res => {
                if (res.status === 200) {
                    setListaClientes(res.data);
                }
            })
            .catch((erro) => console.error(erro));
        }
    }

    function carregaDados() {
        api.get(`Usuario/GetEmpresaLocal`).then(res => {
            if (res.status === 200) {
                if(res.data.length>0)
                {
                    setNomeEmpresa(res.data[0].emp_descricao);
                    if (res.data[0].loc_descricao != "")
                        setNomeLocal(" - "+res.data[0].loc_descricao);
                }
            }
            else{
                console.log('empresa/local',res);
            }
        }, err => {
            console.log(err.response)
        });
    }
    const showDrawerBusca = () => {
        setOpenBuscaGlobal(true);
    };
    const onCloseBuscaGlobal = () => {
        setOpenBuscaGlobal(false)
    };

    const showDrawerModulos = () => {
        setOpenModalModulos(true);
    };
    const onCloseModulos = () => {
        setOpenModalModulos(false)
    };

    const showDrawerPerfil = () => {
        setOpenPerfil(true);
    };
    const onClosePerfil = () => {
        setOpenPerfil(false)
    };

    const menuUsuario = (
        <Menu className="col-avatar text-right">
            <Menu.Item key="8">
                <Link to="/alterarsenha">
                 <LockOutlined /> Trocar Senha
                </Link>
            </Menu.Item>
            <Menu.Item key="9">
                <Link href="#" onClick={() => logout()}>
                    <LogoutOutlined /> Sair
                </Link>
            </Menu.Item>
        </Menu>
    );

    return (

        <Layout.Header>
            <Row align="middle" justify="center">
            {codCliLogado<=0 ?
                <Col span={23}>
                    <Row align="middle">
                        <Col span={5} className="text-center">
                            <img src={logo} className="logo" alt="Logo" />
                        </Col>
                        
                        <Col span={12} className="text-center">
                            <p className="f-25"><b>{nomeEmpresa} {nomeLocal}</b></p>
                        </Col>
                        <Col span={7} align="center">
                            <Select showSearch
                                placeholder="Selecione um Cliente"
                                optionFilterProp="children"
                                onChange={(valor) => setClienteSelecionado(valor)}
                            >
                                <Option key={0} value={0}> Selecionar Cliente... </Option>
                                {listarClientes.map((x)=>
                                        <Option key={x.cliId} value={x.cliId}> {x.cliCodigo} - {x.cliNome} </Option>
                                    )}
                                </Select>
                        </Col>
                    </Row>
                </Col>:<Col span={23}>
                    <Row align="middle">
                        <Col span={5} className="text-center">
                            <img src={logo} className="logo" alt="Logo" />
                        </Col>
                        
                            <Col span={19} align="center">
                                <Col span={11} align="center">
                                    <p className="f-25"><b>{nomeEmpresa} {nomeLocal}</b></p>
                                </Col>
                            </Col>
                        </Row>
                    </Col>
                }

                <Col span={1} className="text-right">
                    <Dropdown overlay={menuUsuario} trigger={['click']} placement="bottomRight" arrow>
                        <span>
                            <Avatar size="large" src="" />
                        </span>
                    </Dropdown>
                </Col>
            </Row>

            <ModalEmpresaFilial exibirModalEmpresaFilial={openModalEmpresaFilial} fecharModalEmpresaFilial={() => setOpenModalEmpresaFilial(false)} />

            <Drawer title="Busca Global"
                placement="top"
                height={130}
                visible={openBuscaGlobal}
                onClose={onCloseBuscaGlobal}>
                <Input.Search placeholder="Pesquisar..." enterButton />
            </Drawer>

            <Drawer title="Módulos"
                height='100%'
                placement="top"
                className="drawer-modulos"
                visible={openModalModulos}
                onClose={onCloseModulos}>
                <ModulosPage />
            </Drawer>

            <Drawer title="Meu Perfil"
                width="50%"
                visible={openPerfil}
                onClose={onClosePerfil}
                footer={
                    <div className="right">
                        <Button onClick={onClosePerfil} size="large" className="m-r-8">
                            Cancelar
                        </Button>
                        <Button icon={<SaveFilled />} size="large" type="primary">
                            Salvar
                        </Button>
                    </div>
                }>
                <Perfil />
            </Drawer>
        </Layout.Header>

    );

}