import React, { useState, useEffect } from 'react';
import { Form, Row, Button, Input, Select, Col, Table, Tag, Drawer, Modal, notification } from 'antd';
import { CloseOutlined, SearchOutlined, ExclamationCircleOutlined, SendOutlined } from "@ant-design/icons";
import "../../App.less";
import api from '../../services/api';
import FilterClients from './filterClients';


export default function ManutencaoMensagem({ dataRegister, exibirDrawer, fecharDrawer }) {

    const [formSendSMS] = Form.useForm();

    const [loading, setLoading] = useState(false);

    const [OpenFilter, setOpenFilter] = useState(false);
    const [loadMessage, setLoadMessage] = useState(false);
    const [verifyClientes, setVerifyClientes] = useState(false);
    const [listaMensagemMarketing, setListaMensagemMarketing] = useState([]);

    const [filterClientes, setFilterCliente] = useState(null);
    const [loadingClientes, setLoadingClientes] = useState(false);
    const [listClientes, setListClientes] = useState([]);
    const [filterMensagem, setFilterMensagem] = useState("");


    useEffect(() => {
        if (dataRegister.ctmId > 0) {
            console.log("Form data ", dataRegister);
            formSendSMS.setFieldsValue(dataRegister);
            setListClientes(dataRegister.clientes);
        } else {
            setListClientes([]);
            formSendSMS.resetFields();
            formSendSMS.setFieldsValue({ ctmId: 0 });
        }
    }, [dataRegister]);

    useEffect(() => loadData(), [filterMensagem]);
    function loadData() {
        setLoadMessage(true);
        api.get(`MensagemMarketing/GetAll?numRegister=100&numPage=1&filter=${filterMensagem}`).then(res => {
            if (res.status === 200) {
                console.log("Dados mensagem ======= ", res);
                setListaMensagemMarketing(res.data.data);
            }
        }, err => {
            console.log(err.response)
        }).finally(() => setLoadMessage(false));
    }

    useEffect(() => loadClientes(), [filterClientes]);
    function loadClientes() {
        setLoadingClientes(true)
        setListClientes([]);
        if (filterClientes === null)
            return;

        api.post(`Clientes/GetClientesByFilters`, filterClientes).then(res => {
            if (res.status === 200)
                setListClientes(res.data);
        }, err => {
            console.log(err.response)
        }).finally(() => setLoadingClientes(false));
    }

    const onFinish = values => {
        if (listClientes.length === 0) {
            setVerifyClientes(true);
            return;
        }
        setVerifyClientes(false);
        values.filterClientes = filterClientes;
        api.post('/MensagensClientes/save', values).then(res => {
            if (res.status === 200) {
                notification.success({ message: 'Operação concluída sucesso!', description: "Mensagem enviada com sucesso!" });
                fecharDrawer();
            }
        }, err => {
            notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
        }).finally(() => {
        });
    };

    const onChangeMessage = values => {
        formSendSMS.setFieldsValue({ msmDescricao: listaMensagemMarketing.find(x => x.msmId === values).msmDescricao });
        formSendSMS.setFieldsValue({ msmTitulo: listaMensagemMarketing.find(x => x.msmId === values).msmTitulo });
    };

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar? ',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            okType: 'danger',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };

    return (

        <Drawer title="Enviar Mensagem"
            width="75%"
            destroyOnClose={true}
            visible={exibirDrawer}
            onClose={onClose}
            footer={
                <div className="right">
                    <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button" className="m-r-8">
                        Cancelar
                </Button>
                    <Button loading={loading} icon={<SendOutlined />} size="large" type="primary" htmlType="submit" onClick={() => formSendSMS.submit()}>
                        Enviar
                </Button>
                </div>
            }>
            <div className="App">
                <Row align="top" gutter={[8, 8]}>
                    <Col sm={8}>
                        <div>
                            <Form layout="vertical"
                                name="formSendSMS"
                                form={formSendSMS}
                                onFinish={onFinish}>
                                <Form.Item name="ctmId" hidden />
                                <Form.Item label="Mensagem Modelo" name="msmId">
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        placeholder="Selecione uma mensagem modelo"
                                        onChange={onChangeMessage}>

                                        {listaMensagemMarketing.map(res => (
                                            <Select.Option key={res.msmId} value={res.msmId}>{`${res.msmId} - ${res.msmTitulo}`}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Título da Mensagem" name="msmTitulo" rules={[{ required: true, message: 'Um título deve ser informado!' }]}>
                                    <Input placeholder="Título..." />
                                </Form.Item>
                                <Form.Item label="Preview Mensagem" name="msmDescricao" rules={[{ required: true, message: 'Uma mensagem deve ser informada!' }]}>
                                    <Input.TextArea placeholder="Mensagem..." className="textarea" rows={6} />
                                </Form.Item>
                            </Form>
                        </div>
                    </Col>
                    <Col sm={1}>
                    </Col>
                    <Col sm={15}> 

                        <Row align="middle" gutter={[8, 8]}>
                            <Col align="middle" sm={24}>
                                <Form.Item>
                                    <Button onClick={() => setOpenFilter(true)} icon={<SearchOutlined />} size="large" type="primary" htmlType="submit">
                                        Selecionar Clientes
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>

                        <div className="tabela">
                            <Table columns={
                                [
                                    {
                                        title: 'Código',
                                        width: 105,
                                        align: 'center',
                                        fixed: 'left',
                                        render: ({ cliId }) => (
                                            <Tag color={'processing'}>
                                                <b>#{cliId}</b>
                                            </Tag>
                                        ),
                                    },
                                    {
                                        title: 'Cliente',
                                        fixed: 'left',
                                        render: ({ cliNome }) => (
                                            <div>
                                                <b>{cliNome}</b>
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Email',
                                        fixed: 'left',
                                        render: ({ cliEmail }) => (
                                            <div>
                                                <span>{cliEmail}</span>
                                            </div>
                                        ),
                                    }
                                ]}
                                dataSource={listClientes}
                            />
                        </div>
                        { !(listClientes.length === 0 && verifyClientes) || 
                            <div className="error" align="center">
                                <div>Selecione a lista de clientes para receber a mensagem.</div>
                            </div>
                        }
                    </Col>
                </Row>
                <FilterClients exibirDrawer={OpenFilter} setFilterCliente={setFilterCliente} fecharDrawer={() => setOpenFilter(false)}></FilterClients>
            </div>
        </Drawer>
    );
}