import React, { useEffect, useState } from "react";
import { Row, Col, Tooltip, Form, Input, Table, Tag, } from "antd";
import {
    QuestionCircleOutlined, ArrowDownOutlined, ArrowUpOutlined,
} from "@ant-design/icons";

import "../../App.less";
import api from '../../services/api';

export default function Clientes() {

    const [carregando, setCarregando] = useState(false);
    const [openManutencao, setOpenManutencao] = useState(false);
    const [registroManutencao, setRegistroManutencao] = useState({});

    const [pesquisaCliente, setPesqueisaCliente] = useState("");
    const [listaClientes, setListaClientes] = useState([]);
    const [totalList, setTotalList] = useState(0);

    const [numPage, setNumPage] = useState(1);
    const [numRegister, setNumRegister] = useState(10);

    useEffect(() => carregaClientes(), [numRegister, numPage, pesquisaCliente]);

    function carregaClientes() {
        setCarregando(true);
        api.get(`Clientes/GetAllFilters?numRegister=${numRegister}&numPage=${numPage}&filters=${pesquisaCliente}`).then(res => {
            if (res.status === 200) {
                setListaClientes(res.data.data.map((x)=>({key: x.cliId, ...x})));
                setTotalList(res.data.total);
            }
        }, err => {
            console.log(err.response)
        }).finally(() => setCarregando(false));
    }

    

    return (
        <div className="p-10">
            <div>
                <Row align="middle" gutter={[8, 8]}>
                    <Col span={24}>
                        <span>
                            <h2 nameClass="title-dashboard">Clientes</h2>
                        </span>
                    </Col>
                </Row>
                <Form layout="vertical">
                    <Row align="middle" gutter={[8, 8]}>
                        <Col sm={24}>
                            <Form.Item>
                                <div>
                                    <label> Pesquisar por </label>
                                    <Tooltip title="Código, Nome, CPF, CEP, Cidade">
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                </div>
                                <Input.Search allowClear="true" placeholder="Código, Nome, CPF, CEP, Cidade..."
                                    value={pesquisaCliente} onChange={e => setPesqueisaCliente(e.target.value)}
                                    enterButton />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div>

                <div className="tabela">
                    <Table scroll={{ x: 1000 }} columns={
                        [
                            {
                                title: 'Código',
                                width: 105,
                                align: 'center',
                                fixed: 'left',
                                render: ({ cliId }) => (
                                    <Tag color={'processing'}>
                                        <b>#{cliId}</b>
                                    </Tag>
                                ),
                            },
                            {
                                title: 'Cliente',
                                fixed: 'left',
                                render: ({ cliNome }) => (
                                    <div>
                                        <b>{cliNome}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'CPF',
                                fixed: 'left',
                                render: ({ cliCpfCnpj }) => (
                                    <div>
                                        <span>{cliCpfCnpj}</span>
                                    </div>
                                ),
                            },
                            {
                                title: 'Email',
                                fixed: 'left',
                                render: ({ cliEmail }) => (
                                    <div>
                                        <span>{cliEmail}</span>
                                    </div>
                                ),
                            },
                            {
                                title: 'Telefone',
                                align: 'center',
                                width: 125,
                                render: ({ cliTelefone }) => (
                                    <div>
                                        <b>{cliTelefone || 'Não Informado'}</b>
                                    </div>
                                ),
                            },

                        ]} expandable={{
                            expandedRowRender: ({ cliEndereco, cliBairro, cliCep, cliUf }) =>
                                <div>
                                    <Row align="middle" gutter={[8, 0]}>
                                        <Col span={12}>
                                            Endereço: <b>{cliBairro || 'Não Informado!'}{(!!cliEndereco) ? (', ' + cliEndereco) : ''}{!!(cliBairro) ? (', ' + cliBairro) : ''} {!!(cliCep) ? ('- ' + cliCep) : ''} {!!(cliUf) ? ('- ' + cliUf) : ''}</b>
                                        </Col>
                                    </Row>
                                </div>,
                            expandIcon: ({ expanded, onExpand, record }) =>
                                expanded ? (
                                    <ArrowUpOutlined onClick={e => onExpand(record)} />
                                ) : (
                                        <ArrowDownOutlined onClick={e => onExpand(record)} />
                                    )
                        }}
                        pagination={{
                            current: numPage,
                            pageSize: numRegister,
                            total: totalList,
                            onChange: (pg) => setNumPage(pg),
                            onShowSizeChange: (current, page) => setNumRegister(page)
                        }}
                        dataSource={listaClientes}
                    />
                </div>
            </div>
        </div>
    );

}