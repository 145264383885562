import React, { useState } from "react";
import { Row, Col, Form, Input, Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}
  
function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('Você só pode fazer upload de arquivos JPG/PNG!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('A imagem deve ter menos de 2 MB!');
    }
    return isJpgOrPng && isLt2M;
}

export default function Perfil() {

    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();

    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
          setLoading(true);
          return;
        }
        if (info.file.status === 'done') {
            getBase64(info.file.originFileObj, (imageUrl) => {
                setLoading(false);
                setImageUrl(imageUrl);
            });
        }
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div className="ant-upload-text">Enviar Imagem</div>
        </div>
    );

    return (
        <div>
            <Form layout="vertical">
                <Row align="middle" justify="center" gutter={[16, 0]}>
                    <Col span={7}>
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            beforeUpload={beforeUpload}
                            onChange={handleChange}
                        >
                            {imageUrl ? 
                                <img src={imageUrl} alt="avatar" /> 
                            : uploadButton}
                        </Upload>
                    </Col>
                    <Col span={17}>
                        <Row align="middle" gutter={[0, 8]}>
                            <Col span={24}>
                                <Form.Item label="Nome">
                                    <Input placeholder="Informe seu Nome" />   
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="E-mail">
                                    <Input placeholder="Informe seu E-mail" />   
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}