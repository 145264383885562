import React, { useEffect, useState } from "react";
import { Row, Col, Tooltip, Form, Input, Select, Button, Table, Tag, Popover, Dropdown, Menu } from "antd";
import {
    QuestionCircleOutlined, DeleteFilled, PlusSquareFilled, MoreOutlined, PrinterFilled, EditFilled
} from "@ant-design/icons";

import "../../App.less";
import { BreadcrumbPage } from "../../components";
import ManutencaoMensagem from './manutencao';
import api from '../../services/api';

export default function Fornecedor() {

    const [open, setOpen] = useState(false);
    const [carregando, setCarregando] = useState(false);
    const [openManutencao, setOpenManutencao] = useState(false);
    const [registroManutencao, setRegistroManutencao] = useState({});
    const [listaMensagemMarketing, setListaMensagemMarketing] = useState({});
    const [dataRegister, setDataRegister] = useState({});

    const [ page, setPage ] = useState(1);
    const [ numPage, setNumPage] = useState(10);

    const [pesquisaMensagem, setPesquisaMensagem] = useState("");
    const [onInitIndex, setOnInitIndex] = useState(false);

    async function showDrawer(item) {
        if (item.msmId > 0) {
            setDataRegister(item);
        } else setDataRegister({ msmId: 0 });
        setOpenManutencao(true);
    };

    useEffect(() => carregaMensagem(), [pesquisaMensagem,numPage,page,onInitIndex, openManutencao]);

    function carregaMensagem() {
        api.get(`MensagemMarketing/GetAll?numRegister=${numPage}&numPage=${page}&filter=${pesquisaMensagem}`).then(res => {
            setCarregando(true);
            if (res.status === 200) {   
                setListaMensagemMarketing(res.data);
            }
        }, err => {
            console.log(err.response)
        }).finally(() => setCarregando(false));
    }


    const menuTable = (
        <Menu className="text-right">
            <Menu.Item key="1">
                <Button type="link" icon={<DeleteFilled />}> Remover </Button>
            </Menu.Item>
            <Menu.Item key="2">
                <Button type="link" icon={<PrinterFilled />}> Editar </Button>
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="p-10">
            <Row align="middle" gutter={[8, 8]}>
                <Col span={20}>
                    <span>
                        <h2 nameClass="title-dashboard">Mensagens</h2>
                    </span>
                </Col>
                <Col span={4}>
                    <Button type="primary" className="right" icon={<PlusSquareFilled />} size="middle" onClick={() => showDrawer({})}>
                        NOVO
                    </Button>
                </Col>
            </Row>
            <div>
                <Form layout="vertical">
                    <Row align="middle" gutter={[8, 8]}>
                        <Col sm={24}>
                            <Form.Item>
                                <div>
                                    <label> Pesquisar por </label>
                                    <Tooltip title="Mensagem">
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                </div>
                                <Input.Search allowClear="true" placeholder="Mensagem..." value={pesquisaMensagem}
                                    onChange={e => setPesquisaMensagem(e.target.value)}
                                    enterButton />
                            </Form.Item>
                        </Col>
                        {/* <Col sm={8}>
                            <Form.Item label="Ordenar por">
                                <Select showSearch placeholder="Nome A-Z">
                                    <Select.Option value="a-z">Mensagem A-Z</Select.Option>
                                    <Select.Option value="z-a">Mensagem Z-A</Select.Option>
                                    <Select.Option value="crescente">Ativo</Select.Option>
                                    <Select.Option value="decrescente">Inativo</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col> */}
                    </Row>
                    {/* <Collapse className="p-relative topDif" accordion ghost destroyInactivePanel={true}>
                        <div className="colDet"></div>
                        <Collapse.Panel key="1" showArrow={false} extra={
                            <Button type="primary" onClick={togglePesquisa}>
                                {open ? <ArrowUpOutlined /> : <ArrowDownOutlined />} Realizar pesquisa avançada
                            </Button>
                        }>
                            <Row align="middle" gutter={[8, 8]}>
                                <Col span={8}>
                                    <Form.Item label="Campo Personalizado">
                                        <Select showSearch placeholder="Selecione um campo personalizado" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Condição">
                                        <Select placeholder="Selecione uma condição..." />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Filtro">
                                        <Input placeholder="Informe o filtro para essa condição" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row align="middle" gutter={[8, 8]}>
                                <Col span={24}>
                                    <Button type="primary" className="right" icon={<SearchOutlined />}>
                                        Aplicar Filtros
                                    </Button>
                                </Col>
                            </Row>
                        </Collapse.Panel>
                    </Collapse> */}
                </Form>
            </div>
            <div>
                <div className="tabela">
                    <Table loading={carregando} scroll={{ x: 800 }} columns={
                        [             
                            {
                                title: 'Código',
                                width: 105,
                                align: 'center',
                                fixed: 'left',
                                render: ({ msmId, msmAtivo }) => (
                                    <Popover content={msmAtivo ? 'Ativo' : 'Inativo'} placement="right">
                                        <Tag color={msmAtivo ? 'processing' : 'default'}>
                                            <b>#{msmId}</b>
                                        </Tag>
                                    </Popover>
                                ),
                            },
                            {
                                title: 'Título',
                                fixed: 'left',
                                render: ({ msmTitulo }) => (
                                    <div>
                                        <b>{msmTitulo}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Mensagem',
                                fixed: 'left',
                                render: ({ msmDescricao }) => (
                                    <div>
                                        <b>{msmDescricao}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Operações',
                                dataIndex: '',
                                align: 'center',
                                fixed: 'right',
                                key: 'x',
                                width: 85,
                                render: (data) => (
                                    <div>
                                        <Button onClick={() => showDrawer(data)} icon={<EditFilled />} />                               
                                    </div>
                                ),
                            },
                        ]} 
                        pagination={{
                            current: page,
                            pageSize: numPage,
                            total: listaMensagemMarketing.total,
                            onChange: (pg) => setPage(pg),
                            onShowSizeChange: (current, page) => setNumPage(page) 
                        }}
                        dataSource={listaMensagemMarketing.data}
                    />
                </div>
            </div>
            <ManutencaoMensagem dataRegister={dataRegister} dadosManutencao={registroManutencao} setRegistroManutencao={setRegistroManutencao} exibirDrawer={openManutencao} fecharDrawer={() => setOpenManutencao(false)} onInitIndex={setOnInitIndex} />
        </div>
    );

}