import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Select,Layout, Typography} from 'antd';
import { UnlockFilled, MailOutlined, SmileOutlined, SendOutlined } from '@ant-design/icons';
import api from '../../services/api';
import {getUserPerm} from '../../services/auth';

//import "../../login/login.less";
import logo from '../../assets/logoGestor.png';
import { ModulosPage } from "../../components";


export default function SelecionarCliente() {

    // const acessar = values => {
    //     window.location = "/Home"
    // };

    // const [codigoVendedor, setCodigoVendedor] = useState(getUserPerm().ven_id);
    // const [ codigoEmpresa, setCodigoEmpresa] = useState(null);
    // const [ codigoCliente, setCodigoCliente] = useState(null);
    // const [ listarClientes, setListaClientes ] = useState([]);
    
    // function carregarDados() {
    //     console.log(codigoVendedor);
    //     api.get('/Clientes/GetAllVendedor?codeVend='+codigoVendedor).then(res => {
    //         console.log(res);
    //         if (res.status === 200) {
    //             setListaClientes(res.data);
    //             if (res.data.length === 1) setCodigoCliente(res.data[0].cli_codigo)
    //         }
    //     })
    //     .catch((erro) => console.error(erro));
    // }

    // function selecionarCliente(){
    //     if(codigoCliente!= null)
    //     {
    //         alert(codigoCliente);
    //     }
    //     //getUserPerm()
    //     //pegar o que já tem e ncluir o cod do cliente e setar ele de volta
    //     //setUser()
    // }
    
   
    // useEffect(() => {
    //     if (codigoLocal){
    //         setEmpresa(codigoEmpresa);
    //         setLocal(codigoLocal);
    //         window.location="/Home";
    //     }
    // },[codigoLocal]);

    return (
                        <Form.Item name="cliente">
                            <Select 
                            placeholder="Selecione um Cliente"
                            optionFilterProp="children"
                            //onChange={(valor) => setCodigoCliente(valor)}
                            // value={codigoLocal}
                        >
                             {/* {listarClientes.map((x)=>
                                     <Option key={x.cliCodigo} value={x.cliCodigo}> {x.cliNome} </Option>
                                )} */}
                            </Select>
                        </Form.Item>
    );
}