import React, { useEffect, useState } from "react";
import { Row, Col, Form, Tooltip, Input, Table, Tag, Carousel, Radio,Button } from 'antd';
import { QuestionCircleOutlined, LeftOutlined, RightOutlined,SettingOutlined } from '@ant-design/icons';

import moment from 'moment';
import "../../App.less";
import api from '../../services/api';

export default function ConsultaAcessos() {

    const [carregando, setCarregando] = useState(false);
    const [listaPesquisa, setListaPesquisa] = useState([]);
    const [pesquisaPeca, setPesquisaPeca] = useState("");

    const [numPage, setNumPage] = useState(1);
    const [numRegister, setNumRegister] = useState(25);
    const [totalList, setTotalList] = useState(0);

    useEffect(() => carregaPesquisa(), [numRegister, numPage,pesquisaPeca]);
    
    function carregaPesquisa() {
        setCarregando(true);
        api.get(`CatPesquisaPecas/GetAllFilters?numRegister=${numRegister}&numPage=${numPage}&filters=${pesquisaPeca}`).then(res => {
            if (res.status === 200) {
                setListaPesquisa(res.data.data.map((x) => ({ key: x.cth_id, ...x })));
                setTotalList(res.data.total);                
            }
        }, err => {
            console.log(err.response)
        }).finally(() => setCarregando(false));
    }
    return (
        <div className="p-10">
            <Row align="middle" gutter={[8, 8]}>
                <Col span={24}>
                    <span>
                        <h2 nameClass="title-dashboard">Consulta Pesquisas</h2>
                    </span>
                </Col>
            </Row>
            <Form layout="vertical">
                <Row align="middle" gutter={[8, 8]}>
                    <Col sm={24}>
                        <Form.Item>
                            <div>
                                <label> Pesquisar por </label>
                                <Tooltip title="Código,Descrição">
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            </div>
                            <Input.Search allowClear="true" placeholder="Peça, Grupo"
                               value={pesquisaPeca} onChange={e => setPesquisaPeca(e.target.value)}
                                enterButton />
                        </Form.Item>
                    </Col>
                    <Col sm={12}></Col>
                </Row>
                <Row align="middle" gutter={[8, 8]}>
                    <Col sm={24}>
                        <div className="tabela">
                            <Table scroll={{ x: 500 }} columns={
                                [
                                    {
                                        title: 'Acesso',
                                        width: 110,
                                        fixed: 'left',
                                        render: ({ cth_hora }) => (
                                            <div>
                                                <span>{moment(cth_hora).format('DD/MM/YYYY HH:MM')}</span>
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Cliente',
                                        width: 200,
                                        fixed: 'left',
                                        render: ({ cli_nome }) => (
                                            <div>
                                                <b>{cli_nome}</b>
                                            </div>
                                        ),
                                    },
                                    
                                    {
                                        title: 'Peça',
                                        fixed: 'left',
                                        render: ({ ctg_nome }) => (
                                            <div>
                                                {ctg_nome}
                                            </div>
                                        ),
                                    },

                                    {
                                        title: 'Grupo',
                                        fixed: 'left',
                                        render: ({ ctd_nome }) => (
                                            <div>
                                                {ctd_nome}
                                            </div>
                                        ),
                                    },
                                   
                                ]} pagination={{
                                    current: numPage,
                                    pageSize: numRegister,
                                    total: totalList,
                                    onChange: (pg) => setNumPage(pg),
                                    onShowSizeChange: (current, page) => setNumRegister(page)
                                }}
                                dataSource={listaPesquisa}
                                expandable={{
                                    expandedRowRender: record => <p style={{ margin: 0 }}>Tela: {record.cth_tela} <br/>Pesquisa: {record.cth_pesquisa}</p>
                                  }}
                                />
                        </div>
                    </Col>
</Row>
            </Form>
        
        </div>
    );

}