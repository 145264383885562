import React from "react";
import { Row, Col, Form, Select } from "antd";
import Modal from "antd/lib/modal/Modal";

import "../../App.less";

export default function ModalEmpresaFilial ({exibirModalEmpresaFilial, fecharModalEmpresaFilial}) {

    const onCloseModal = () => {
        fecharModalEmpresaFilial()
    };

    return (

        <Modal centered
            title="Empresas/Filiais"
            visible={exibirModalEmpresaFilial}
            onCancel={onCloseModal}
            onOk={onCloseModal}
            okText="Alterar Acesso"
        >
            <Row align="middle">
                {/* <Col span={24}>
                    Você está acessando: <b>Empresa teste / Três de maio</b>
                </Col> */}
            </Row>
            <Form layout="vertical">
                <div className="m-t-10">
                    <Row align="middle">
                        <Col span={24}>
                            <Row align="middle">
                                <Col span={24}>
                                    <Form.Item label="Selecione sua Empresa">
                                        <Select placeholder="Selecione sua Empresa"/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="Selecione sua Fial">
                                        <Select placeholder="Selecione sua Fial"/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Form>
        </Modal>
        
    );
}