import React,{ useEffect, useState } from 'react';
import { Row, Col,Button, Drawer, Modal, notification, Table,Space,Card,Pagination } from "antd";
import { CloseOutlined, SaveFilled, ExclamationCircleOutlined,LeftCircleOutlined,RightCircleOutlined,FileImageOutlined } from "@ant-design/icons";
import PinchZoomPan from "react-responsive-pinch-zoom-pan";
import "../../App.less";
import api from '../../services/api';
import {urlApi} from '../../services/auth';

export default function DrawerGrupo({codCatalago,codLinha, exibirDrawer, fecharDrawer,nomeCatalogo, nomeLinha}) {
    const [ formImagemGrupo, setFormImagemGrupo ] = useState(null)
    const [ carregando, setCarregando ] = useState(false);
    const [numPage, setNumPage] = useState(1);
    const [numRegister, setNumRegister] = useState(15);
    const [numPageSub, setNumPageSub] = useState(1);
    const [numRegisterSub, setNumRegisterSub] = useState(15);
    const [listaGrupo, setListaGrupo] = useState([]);
    const [codGrupo, setCodGrupo] = useState("");
    const [fotoGrupo,setFotoGrupo] = useState("");
    const [fotoSubGrupo,setFotoSubGrupo] = useState("");
    const [codSubGrupo,setCodSubGrupo] = useState("");
    const [listaSubGrupo,setListaSubGrupo] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([""]);
    const [selectedRows, setSelectedRows] = useState([0]);
    const [resolucao, setResolucao] = useState(0);

    useEffect(() =>  setResolucao(window.innerHeight));

    useEffect(() => carregaGrupos(),[numRegister, numPage,codCatalago,codLinha]);

    useEffect(() => setFocusToTable(), [listaGrupo]);

    useEffect(() => selecionaPrimeiraLinha(), [listaGrupo]);

    useEffect(() => selecionaFotoSubGrupo(), [listaSubGrupo]);
    
    function onSelectChange(selectedRowKeys,selectedRows) {
        setSelectedRowKeys(selectedRowKeys);
        setFotoGrupo(selectedRows[0].ctd005);
        setCodGrupo(selectedRows[0].ctd001);
        setListaSubGrupo(selectedRows[0].cat006.map((x) => ({ key: x.ctf_id, ...x })));
    };

    const rowSelection = {
        selectedRowKeys,selectedRows,
        onChange: onSelectChange,
        type: 'radio',
        columnWidth: 18,
      };

    const imgPeca = {
        width: 'auto',
        height: '280px'
      };

      function carregaGrupos() {
        setCarregando(true);
        api.get(`CatGrupo/GetAllGrupoPorCatLinha?codCatalago=${codCatalago}&codLinha=${codLinha}`).then(res => {
            if (res.status === 200) {
                setListaGrupo(res.data.map((x) => ({ key: x.key, ...x })));
              }
        }, err => {
            console.log(err.response);
            setCarregando(false);
        }).finally(() => setCarregando(false));
    }

    function FecharDrawer(){
        if(fecharDrawer == true){
            fecharDrawer();
        }
    }

    const onClose = () => {
        fecharDrawer();
    };

    function setFocusToTable(){
        
        if(listaGrupo.length >0 && document.getElementsByClassName("ant-radio-input").length >0)
        {
            document.getElementsByClassName("ant-radio-input")[0].focus();
            document.getElementsByClassName("ant-radio-input")[0].checked = true;
            if(numPage==1)
            {
                setSelectedRowKeys([listaGrupo[0].key]);
                setFotoGrupo(listaGrupo[0].ctd005);
                setCodGrupo(listaGrupo[0].ctd001);
                setListaSubGrupo(listaGrupo[0].cat006.map((x) => ({ key: x.key, ...x })));
            }
            if (numPage >1) 
            {
                setSelectedRowKeys([listaGrupo[(numRegister*(numPage-1))].key]);
                setFotoGrupo(listaGrupo[(numRegister*(numPage-1))].ctd005);
                setCodGrupo(listaGrupo[(numRegister*(numPage-1))].ctd001);
                setListaSubGrupo(listaGrupo[(numRegister*(numPage-1))].cat006.map((x) => ({ key: x.key, ...x })));
            }
            
        }
    }

    function selecionaPrimeiraLinha()
    {
        if(listaGrupo.length>0)
        {
            if(numPage==1)
            {
                setFotoGrupo(listaGrupo[0].ctd005);
                setCodGrupo(listaGrupo[0].ctd001);
                setListaSubGrupo(listaGrupo[0].cat006);
                selecionaFotoSubGrupo();
            }
            if (numPage >1) 
            {
                setFotoGrupo(listaGrupo[(numRegister*(numPage-1))].ctd005);
                setCodGrupo(listaGrupo[(numRegister*(numPage-1))].ctd005);
                setListaSubGrupo(listaGrupo[(numRegister*(numPage-1))].cat006);
                selecionaFotoSubGrupo();
            }
        }
    }

    function selecionaFotoSubGrupo()
    {
        if(listaSubGrupo.length>0)
        {
            document.getElementById("tabelaSubGrupo").getElementsByClassName("ant-radio-input")[0].checked =true;
            setFotoSubGrupo(listaSubGrupo[0].ctf005);
        }
    }

    return (
        <Drawer title={<b>Linha: {codLinha} - {nomeLinha}    Catálogo: {codCatalago} - {nomeCatalogo}</b>}
            width="100%"
            visible={exibirDrawer}
            onClose={onClose}
            extra={
                <Space>
                    <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button" className="m-r-8">
                        Voltar
                    </Button>
                </Space>
              }
            > 
             <Row align="top">
                    <Col sm={12}>
                    <div className="tabela hgtGrupoCat" id="TabelaGrupo">
                            <Table small loading={carregando} columns={
                                [
                                    {
                                        title: 'Código',
                                        width: 80,
                                        fixed: 'left',
                                        render: ({ ctd001 }) => (
                                            <div>
                                                <b>{ctd001}</b>
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Descrição do Grupo',
                                        fixed: 'left',
                                        render: ({ ctd002 }) => (
                                            <div>
                                                <b>{ctd002}</b>
                                            </div>
                                        ),
                                    },
                                ]} 
                                pagination={false}
                                dataSource={listaGrupo}
                                rowSelection={rowSelection}
                                scroll={{ y: resolucao }}
                            />
                        </div>
                    </Col>
                    <Col sm={12}>
                        
                        <Row align="middle" gutter={[8, 8]}>
                            <div className="divimgGrupoCat">
                                <PinchZoomPan initalScale={1} maxScale={15}>
                                <img  src={`${urlApi}CatGrupo/GetImgGrupo?ctd003=${codCatalago}&ctd001=${codGrupo}`}/>
                                </PinchZoomPan>
                            </div>
                        </Row>
                    </Col>
            </Row>
            {
                listaSubGrupo.length>0?
                <Row align="top" gutter={[8, 8]}>
                    <Col sm={12}>
                    <div className="tabela hgtGrupoSubGCat" id="tabelaSubGrupo">
                            <Table small columns={
                                [
                                    {
                                        title: 'Código',
                                        width: 80,
                                        fixed: 'left',
                                        render: ({ ctf001 }) => (
                                            <div>
                                                <b>{ctf001}</b>
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Descrição do Sub-Grupo',
                                        fixed: 'left',
                                        render: ({ ctf003 }) => (
                                            <div>
                                                <b>{ctf003}</b>
                                            </div>
                                        ),
                                    },
                                ]} 
                                pagination={{
                                    current: numPageSub,
                                    pageSize: numRegisterSub,
                                    onChange: (pg) => setNumPageSub(pg),
                                    onShowSizeChange: (current, page) => setNumRegister(page)
                                }}
                                dataSource={listaSubGrupo}
                                rowSelection={{
                                    onChange: (selectedRowKeys, selectedRows) => {
                                        setFotoSubGrupo(selectedRows[0].ctf005);
                                        setCodSubGrupo(selectedRows[0].ctf001);
                                    },
                                     type: 'radio'
                                }}
                                scroll={{ y: resolucao }}
                            />
                        </div>
                    </Col>
                    <Col sm={12}>
                        <Row align="middle" gutter={[8, 8]}>
                            <div className="divimgGrupoSubCat">
                                <PinchZoomPan initalScale={1} maxScale={15}>
                                    <img  src={`${urlApi}CatSubgrupo/GetImgSubGrupo?ctf004=${codCatalago}&ctf002=${codGrupo}&ctf001=${codSubGrupo}`}/>
                                </PinchZoomPan>
                            </div>
                        </Row>
                    </Col>
                </Row>:<span><b>Sem Sub-Grupos informados</b></span>
            }
        </Drawer>      
    )
}