export default function formatNumber(valor, cifrao) {
    if(cifrao)
    {
        let f = valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
        return f;
    }
    else{
        const BRL = new Intl.NumberFormat("pt-br");
        let f = BRL.format(valor);
        //f = parseFloat(f).toFixed(2);
        let str = f.toString();
        if(str.substr(str.length-3, 1) == '.')
        {
            str = str.replace('.',',');
        }
        if(str.substr(str.length-2, 1) == ',' )
        {
            str = str+'0';
        }
        else if(str.substr(str.length-3, 1) != ',')
        {
            str = str+',00';
        }
        return str;
    }
}
        