import React from 'react';
import { Route, Switch, HashRouter } from "react-router-dom";
import { Layout } from "antd";

import { MenuPage, HeaderPage } from "../../components";


import { 
    Dashboard, Login, Clientes, Fornecedor, SendSms,ConsultaPecas,ConsultaAcessos,ConsultaCatalogo,AlterarSenha} from "../";

const { Content } = Layout;


export default function Home() {

    return (
        <HashRouter>
            <div className="App">
                <Layout className="vh100">
                    <HeaderPage />
                    <Layout className="vh100 m-t-60">
                        <MenuPage />
                        <Layout className="site-layout">
                            <Content>
                                <Switch>
                                    <Route exact path="/" component={Dashboard} />
                                    <Route exact path="/login" component={Login} />                                    
                                    <Route exact path="/clientes" component={Clientes} />     
                                    <Route exact path="/fornecedor" component={Fornecedor} />  
                                    <Route exact path="/sendSms" component={SendSms} />  
                                    <Route exact path="/consultapecas" component={ConsultaPecas} />
                                    <Route exact path="/consultacatalogo" component={ConsultaCatalogo} />
                                    <Route exact path="/consultaacessos" component={ConsultaAcessos} />
                                    <Route exact path="/alterarsenha" component={AlterarSenha} />
                                    {/* <Route exact path="/selecionarcliente" component={SelecionarCliente} /> */}
                                </Switch>
                            </Content>
                        </Layout>
                    </Layout>
                </Layout>
            </div>
        </HashRouter>
    );
    
}