import React from 'react';
import { Row, Col, Form, Input, Button } from 'antd';
import { MailOutlined, ArrowLeftOutlined, SendOutlined } from '@ant-design/icons';

export default function EsqueciSenha({onAcessar}) {
    
    return (
        <div className="m-t-15">
            <Form name="login" size="middle">
                <Row align="middle" gutter={[0, 16]}>
                    <Col span={24}>
                        <Form.Item name="email" rules={[{ required: true, message: 'Informe seu e-mail para recuperar sua senha.' }]}>
                            <Input placeholder="Informe seu e-mail para recuperar sua senha" prefix={<MailOutlined />} />
                        </Form.Item>                                            
                    </Col>
                    <Col span={24}>
                        <div className="flexContent justifyContentBetween">
                            <Form.Item>
                                <Button htmlType="button" onClick={() => onAcessar()} >
                                    <ArrowLeftOutlined /> Acessar
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Recuperar Senha <SendOutlined  />
                                </Button>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}