import React, { useEffect, useState } from "react";
import { Tabs, Row, Col, Form, Input, Switch, notification, Button, Drawer, Modal } from "antd";
import "../../App.less";
import api from "../../services/api";
import { CloseOutlined, SaveFilled, ExclamationCircleOutlined } from "@ant-design/icons";

export default function ManutencaoMensagem({ exibirDrawer, fecharDrawer, dataRegister }) {

    const [formMensagem] = Form.useForm();
    const [carregando, setCarregando] = useState(false);

    useEffect(() => {
        if (!!dataRegister.msmId > 0) {
            formMensagem.setFieldsValue(dataRegister);
        } else {
            formMensagem.resetFields();
            formMensagem.setFieldsValue({ msmId: 0 });
        }
    }, [dataRegister]);

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            okType: 'danger',
            centered: true,
            onOk() {
                //setRegistroManutencao({});
                formMensagem.resetFields();
                fecharDrawer();
            }
        });
    };

    const onFinish = values => {
        api.post('MensagemMarketing/Save', values).then(res => {
            console.log({ res });
            if (res.status === 200) {
                if (res.data?.statusCode)
                    notification.warning({ message: res.data.value.message, description: 'Verifique os dados informados e tente novamente.' });
                else {
                    fecharDrawer();
                    //onInitIndex = true;
                }
            }
        }, err => {
            console.log(err.response)
        }).finally(() => setCarregando(false));

    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Drawer title="Cadastro de Mensagem"
            width="30%"
            visible={exibirDrawer}
            onClose={onClose}
            footer={
                <div className="right">
                    <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button" className="m-r-8">
                        Cancelar
                    </Button>
                    <Button onClick={() => formMensagem.submit()} loading={carregando} icon={<SaveFilled />} size="large" type="primary" htmlType="submit">
                        Salvar
                    </Button>
                </div>
            }>
            <div className="pages-col">
                <Form layout="vertical" form={formMensagem} name="manutencaoMensagem" initialValues={{ remember: true }} onFinish={onFinish} onFinishFailed={onFinishFailed}>
                    <div>
                        <Row align="middle" gutter={[8, 8]}>
                            <Col span={24}>
                                <Form.Item label="Título:" name="msmTitulo" rules={[{ required: true, message: 'Informe o Título' }]}>
                                    <Input placeholder="Informe o Título" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Mensagem" name="msmDescricao" rules={[{ required: true, message: 'Informe a mensagem' }]}>
                                    <Input.TextArea rows="4" placeholder="Informe a mensagem" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="msmAtivo" label="Ativa" valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="msmId" hidden>
                                    <Input placeholder="ID"/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </div>
        </Drawer>

    );
}