import React, { useState,useEffect } from 'react';
import { Layout, Row, Col, Typography, Form, Input, Checkbox, Button, notification } from 'antd';
import { UnlockFilled, MailOutlined, SmileOutlined, SendOutlined } from '@ant-design/icons';

import api from '../../services/api';
import { login, setUser, EMPRESA_KEY, getEntidadeLogado } from '../../services/auth';

import logo from '../../assets/logoGestor.png';
import { ModulosPage } from "../../components";

export default function AlterarSenha() {

    const { Text } = Typography;
    const [carregando, setCarregando] = useState(false);


    const onFinish = values => {   
        setCarregando(true);
        values.emp_key = EMPRESA_KEY;
        values.Logged = true;
        delete values.remember;
        console.log(values);
        api.post('Usuario/VerifyChangePassCatalogo', values).then(res => {
            if (res.status === 200) {
                if(res.data == 1)
                {
                    notification.open({
                        message: 'Alteração Senha',
                        description: 'Senha alterada com sucesso.',
                      });
                      window.location = "/Home";
                }
                else{
                    notification.warning({
                        message: 'Alteração Senha',
                        description: 'Verifique os dados informados e tente novamente.',
                      });
                }
                // alert(res.data);
                // if (res.data?.statusCode)
                //     notification.warning({ message: res.data.value.message, description: 'Verifique os dados informados e tente novamente.' });
                // else {

                // }
            }
        }, err => {
            notification.error({ message: err.response || 'Falha ao alterar senha', description: 'Verifique os dados informados e tente novamente.' });
        }).finally(() => setCarregando(false));
    };

    const onFinishFailed = errorInfo => {
        console.log('Erro ao alterar senha:', errorInfo);
    };

    return (
        <div >
        <Row align="middle" gutter={[8, 8]}>
            <Col span={24}>
                <span>
                    <h2 nameClass="title-dashboard">Alterar Senha</h2>
                </span>

            </Col>
        </Row>
        <div className="m-t-15">
            <Form name="login" size="middle" onFinish={onFinish} onFinishFailed={onFinishFailed}>
                <Row align="middle" gutter={[0, 16]}>
                    <Col span={12}>
                        <Form.Item name="Pass" rules={[{ required: true, message: 'Informe sua senha atual de acesso.' }]}>
                            <Input.Password placeholder="Informe sua senha atual" prefix={<UnlockFilled />} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align="middle" gutter={[0, 16]}>
                    <Col span={12}>
                        <Form.Item name="use_spassword" rules={[{ required: true, message: 'Informe sua nova senha de acesso.' }]}>
                            <Input.Password placeholder="Informe sua nova senha" prefix={<UnlockFilled />} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align="middle" gutter={[0, 16]}>
                    <Col span={12}>
                        <Form.Item name="usu_spasswordconfirm"  dependencies={['use_spassword']} rules={[{ required: true, message: 'Confirme sua nova senha' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                            if (!value || getFieldValue('use_spassword') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('As duas senhas que você digitou não coincidem!'));
                            },
                        }),]}>
                            <Input.Password placeholder="Confirme sua nova senha" prefix={<UnlockFilled />} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align="middle" gutter={[0, 16]}>
                    <Col span={12}>
                        <div className="flexContent justifyContentBetween">
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                        Alterar Senha <SendOutlined />
                                    </Button>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
            </Form>
        </div>
        </div >
        
    );
}