import React, { useState, useEffect } from "react";
import { Row, Col, Form, Radio, Select, Button, Drawer, Modal, DatePicker, Card, Checkbox } from "antd";
import { CloseOutlined, SearchOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import "../../App.less";
import api from "../../services/api";

export default function FilterClients({ exibirDrawer, setFilterCliente, fecharDrawer }) {

    const [formFilterClients] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [abastece, setAbastece] = useState(0);
    const [naoAbastece, setNaoAbastece] = useState(0);
    const [todosClientes, setTodosClientes] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [checkAbastece, setCheckAbastece] = useState(false);
    const [checkNaoAbastece, setCheckNaoAbastece] = useState(false);
    const [region, setRegion] = useState([]);


    useEffect(() => loadData(), []);
    function loadData() {
        setLoading(true);

        api.get(`Locais/GetRegiao`).then(res => {
            if (res.status === 200)
                setRegion(res.data);
        }, err => {
            console.log(err.response)
        }).finally(() => setLoading(false));
    }

    const onFinish = values => {
        setFilterCliente(values);
        fecharDrawer();
    };

    const onChangeAbastece = values => {
        setCheckAbastece(values.target.checked);
        if (checkAbastece) {
            setAbastece(0);
            formFilterClients.setFieldsValue({
                abastece: 0,
            });
            formFilterClients.setFieldsValue({
                periodoAbastece: '',
            });
        }
    }
    const onChangeNaoAbastece = values => {
        setCheckNaoAbastece(values.target.checked);
        if (checkNaoAbastece) {
            setNaoAbastece(0);
            formFilterClients.setFieldsValue({
                naoAbastece: 0,
            });
            formFilterClients.setFieldsValue({
                periodoNaoAbastece: '',
            });
        }

    }


    const onClose = () => {
        setCheckAbastece(false);
        setCheckNaoAbastece(false);
        setAbastece(false);
        setNaoAbastece(false);
        formFilterClients.resetFields();
        fecharDrawer();
    };

    const onValuesChange = value => {

        if (formFilterClients.getFieldValue("todosClientes") == false &&
            formFilterClients.getFieldValue("periodoCadastral") === null &&
            formFilterClients.getFieldValue("periodoNascimento") === null &&
            formFilterClients.getFieldValue("regiao").length === 0 &&
            formFilterClients.getFieldValue("abastece") === 0 &&
            formFilterClients.getFieldValue("naoAbastece") === 0) {
            setDisabled(true);
        }
        else {
            setDisabled(false);
        }

    }

    return (
        <Drawer title="Filtros de pesquisa"
            width="30%"
            visible={exibirDrawer}
            destroyOnClose
            onClose={onClose}
            footer={
                <div className="right">
                    <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button" className="m-r-8">
                        Cancelar
                    </Button>
                    <Button onClick={() => formFilterClients.submit()}
                        loading={loading}
                        disabled={disabled}
                        icon={<SearchOutlined />} size="large" type="primary" htmlType="submit">
                        Buscar
                    </Button>
                </div>
            }>
            <div className="pages-col">
                <Form layout="vertical"
                    form={formFilterClients}
                    name="manutencaoMensagem"
                    onValuesChange={onValuesChange}
                    initialValues={
                        {
                            remember: true,
                            todosClientes: false,
                            periodoCadastral: null,
                            periodoNascimento: null,
                            regiao: [],
                            abastece: 0,
                            naoAbastece: 0
                        }
                    } onFinish={onFinish}>
                    <div>
                        <Row align="middle" gutter={[8, 8]}>
                            <Col span={24}>
                                <Form.Item name="todosClientes" valuePropName="checked">
                                    <Checkbox onChange={(e) => setTodosClientes(e.target.checked)}><b>Todos os clientes</b></Checkbox>
                                </Form.Item>

                            </Col>
                            {todosClientes ||
                                <>
                                    <Col span={24}>
                                        <Form.Item label="Clientes cadastros no período " name="periodoCadastral">
                                            <DatePicker.RangePicker format="DD/MM/YYYY" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item label="Clientes nascidos no período" name="periodoNascimento">
                                            <DatePicker.RangePicker format="DD/MM/YYYY" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item label="Rede de postos" name="regiao">
                                            <Select mode="multiple" placeholder="Selecionar por região" >
                                                {region.map(res => (
                                                    <Select.Option key={res.locId} value={res.locId}>{res.locDescricao}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox onChange={onChangeAbastece} value={checkAbastece}>
                                            <b>Clientes que abastecem á: </b>
                                        </Checkbox>
                                        <div className="site-card-wrapper">
                                            {!checkAbastece ||
                                                <Card title="" bordered={true}>
                                                    <Form.Item name="abastece">
                                                        <Radio.Group onChange={(e) => setAbastece(e.target.value)} value={abastece}>
                                                            <Radio className="inLine" value={1}>Um mês</Radio>
                                                            <Radio className="inLine" value={2}>Dois mês</Radio>
                                                            <Radio className="inLine" value={3}>Três mês</Radio>
                                                            <Radio className="inLine" value={4}>Selecionar por período</Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                    {abastece !== 4 ||
                                                        <Form.Item label="Período de cadastro" name="periodoAbastece">
                                                            <DatePicker.RangePicker format="DD/MM/YYYY" />
                                                        </Form.Item>
                                                    }
                                                </Card>
                                            }
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox onChange={onChangeNaoAbastece}>
                                            <b>Clientes que NÃO abastecem á:</b>
                                        </Checkbox>
                                        <div className="site-card-wrapper">
                                            {!checkNaoAbastece ||
                                                <Card title="" bordered={true}>
                                                    <Form.Item name="naoAbastece">
                                                        <Radio.Group onChange={(e) => setNaoAbastece(e.target.value)} value={naoAbastece}>
                                                            <Radio className="inLine" value={1}>Um mês</Radio>
                                                            <Radio className="inLine" value={2}>Dois mês</Radio>
                                                            <Radio className="inLine" value={3}>Três mês</Radio>
                                                            <Radio className="inLine" value={4}>Selecionar por período</Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                    {naoAbastece !== 4 ||
                                                        <Form.Item label="Período de cadastro" name="periodoNaoAbastece">
                                                            <DatePicker.RangePicker format="DD/MM/YYYY" />
                                                        </Form.Item>
                                                    }
                                                </Card>
                                            }
                                        </div>
                                    </Col>
                                </>
                            }
                        </Row>
                    </div>
                </Form>
            </div>
        </Drawer>
    );
}