import React, { useEffect, useState } from 'react';
import { Button, Col, Drawer, Row, Table, Tooltip } from "antd";
import { CloseOutlined, InfoCircleOutlined, LeftCircleOutlined, PictureOutlined, RightCircleOutlined } from "@ant-design/icons";

import PinchZoomPan from "react-responsive-pinch-zoom-pan";
import api from "../../services/api";
import { urlApi } from "../../services/auth";
import { ModalDadosPecas } from '../../components/modals';
import DrawerAmpliaImagem from './ampliaImagem';


export default function DrawerGrupo({ dadosCatalogoIdx, exibirDrawer, onCloseDrawer }) {
    const [listaPecasGrupo, setListaPecasGrupo] = useState([]);
    const [dadosCatalogo, setDadosCatalogo] = useState({});
    const [codigoPeca, setCodigoPeca] = useState(null);
    const [disabledAnt, setDisabledAnt] = useState(true);
    const [disabledPro, setDisabledPro] = useState(true);
    const [grupoAtual, setGrupoAtual] = useState({});
    const [pecaSelecionada, setPecaSelecionada] = useState(null);
    const [imagemAmpliada, setImagemAmpliada] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [openImg, setOpenImg] = useState(false);
    const [listaGrupo, setListaGrupo] = useState([]);
    const onClose = () => {
        onCloseDrawer()
    };

    const onCloseDrawerImg = () => {
        setOpenImg(false);
    };

    useEffect(() => {
        if (!!dadosCatalogoIdx) {
            setDadosCatalogo(dadosCatalogoIdx);
        }
    }, [dadosCatalogoIdx]);

    useEffect(() => {
        if (!!dadosCatalogo && JSON.stringify(dadosCatalogo) !== "{}") {
            debugger;
            carregarDados();
        }
    }, [dadosCatalogo]);

    useEffect(() => {
        if (!!listaGrupo && listaGrupo.length > 0) {
            statusBotoes()
        }
    }, [listaGrupo]);

    useEffect(() => {
        if (!!pecaSelecionada) {
            setOpenModal(true);
        }
    }, [pecaSelecionada]);

    function carregarDados() {
        carregarPecasGrupo();
        carregaGruposCatalogo();
    }

    function carregarPecasGrupo() {
        api.get(`CatPecas/GetAllPecasporGrupo?codCatalago=${dadosCatalogo.codCatalogo}&codGrupo=${dadosCatalogo.codGrupo}`).then(
            res => {
                setListaPecasGrupo(res.data.map((x) => ({ key: x.rownumber, ...x })))
            }
        ).catch(
            error => {
                console.log(error);
            }
        )
    }

    function carregaGruposCatalogo() {
        api.get(`CatGrupo/GetAllGrupoPorCatalogo?codCatalago=${dadosCatalogo.codCatalogo}&codGrupo=${dadosCatalogo.codGrupo}`).then(res => {
            if (res.status === 200) {
                setListaGrupo(res.data.map((x) => ({ key: x.ctd001, ...x })));
            }
        }
        ).catch(
            err => {
                console.log(err.response)
            }
        );
    }

    function statusBotoes() {
        if (listaGrupo.length > 0) {
            let grupoAtual = {
                nomeGrupo: listaGrupo[0].ctd002,
                codGrupo: listaGrupo[0].ctd001,
                imagemGrupo: listaGrupo[0].imagemgrupo
            }
            setGrupoAtual(grupoAtual);
            if (listaGrupo[0].anterior != null && listaGrupo[0].anterior != "") {
                setDisabledAnt(false);
            }
            else {
                setDisabledAnt(true);
            }
            if (listaGrupo[0].proximo != null && listaGrupo[0].proximo != "") {
                setDisabledPro(false);
            }
            else {
                setDisabledPro(true);
            }
        }
    }

    function onAnterior() {
        if (listaGrupo.length > 0) {
            let cat = { ...dadosCatalogo };
            cat.codGrupo = listaGrupo[0].anterior;
            setDadosCatalogo(cat);
            salvaPesq();
        }
    }

    function onProximo() {
        if (listaGrupo.length > 0) {
            let cat = { ...dadosCatalogo };
            cat.codGrupo = listaGrupo[0].proximo;
            setDadosCatalogo(cat);
            salvaPesq();
        }
    }

    function salvaPesq() {
        if (listaGrupo.length > 0) {
            let historico = {
                cth_pesquisa: `Consulta Peças do Grupo: ${dadosCatalogo.nomeGrupo} Catálogo: ${dadosCatalogo.nomeCatalogo}`,
                cth_observacao: "",
                cth_tela: "Tela de consultas peças do grupo",
                ctg_id: listaGrupo[0].ctg_id,
                ctd_id: listaGrupo[0].ctd_id,
            };
            api.post("CatPesquisaPecas/Save", historico).then(
                res => {
                    console.log(`Salvou o historico: ${JSON.stringify(historico)}`)
                }
            ).catch(
                err => {
                    console.log(err);
                }
            )

        }
    }

    return (
        <Drawer title={<b>Catálogo: {dadosCatalogo.nomeCatalogo}</b>} id="resolucao"
            width="90%"
            visible={exibirDrawer}
            onClose={onClose}
            closable={false}
            extra={
                <Button onClick={onClose} icon={<CloseOutlined />} size="small" htmlType="button" className="m-r-8">
                    Voltar
                </Button>
            }
        >
            <Row align="top" gutter={[8, 8]}>
                <Col sm={12}>
                    <b>Grupo: {grupoAtual.codGrupo} - {grupoAtual.nomeGrupo}</b>
                    <div className="tabela hgt">
                        <Table sticky small columns={
                            [
                                {
                                    title: 'Código Peça',
                                    width: 85,
                                    fixed: 'left',
                                    render: ({ ctg001 }) => (
                                        <div>
                                            <b>{ctg001}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Descrição',
                                    width: 440,
                                    render: ({ ctg002 }) => (
                                        <b>{ctg002}</b>
                                    ),
                                },
                                {
                                    title: 'Item',
                                    width: 30,
                                    render: ({ ctb004 }) => (
                                        <div>
                                            {ctb004}
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Qtde',
                                    width: 40,
                                    render: ({ ctb005 }) => (
                                        <div>
                                            {ctb005}
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Obs',
                                    ellipsis: {
                                        showTitle: false,
                                    },
                                    render: ({ ctb006 }) => (
                                        <Tooltip placement="topLeft" title={ctb006}>
                                            <b>{ctb006}</b>
                                        </Tooltip>
                                    ),
                                },
                                {
                                    title: '',
                                    fixed: 'right',
                                    width: 20,
                                    render: ({ ctg_imagem, ctg001, ctg002 }) => (
                                        <div className={ctg_imagem === "" ? 'hiddencoluna' : 'visiblecoluna'}>
                                            <a onClick={() => {
                                                let imgAmp = {
                                                    url: `${urlApi}CatPecas/GetImgPeca?ctc001=${ctg001}&ctc002=0`,
                                                    titulo: `Peça: ${ctg001} - ${ctg002}`,
                                                };
                                                setImagemAmpliada(imgAmp);
                                                setOpenImg(true);
                                            }}><PictureOutlined /></a>
                                        </div>
                                    ),
                                },
                                {
                                    title: '',
                                    fixed: 'right',
                                    width: 20,
                                    render: ({ ctg001 }) => (
                                        <div>
                                            <a onClick={() => {
                                                setPecaSelecionada(ctg001);
                                            }}><InfoCircleOutlined /></a>
                                        </div>
                                    ),
                                },
                            ]}
                            scroll={{ y: window.innerHeight}}
                            pagination={false}
                            dataSource={listaPecasGrupo}
                            footer={() => <b>Total de Peças: {listaPecasGrupo.length}</b>}
                            rowClassName={(record, index) => record.ctg001 === dadosCatalogo.codPeca ? 'blue' : record.ctg004 === 1 ? 'green' : record.ctg003 === 1 ? 'yellow' : ''}
                        />
                    </div>

                    <Row gutter={[8, 0]}>
                        <Col>
                            <Button type="primary" disabled={disabledAnt} onClick={onAnterior}>
                                <LeftCircleOutlined /> Grupo Anterior
                            </Button>
                        </Col>
                        <Col>
                            <Button type="primary" disabled={disabledPro} onClick={onProximo}>
                                Próximo Grupo <RightCircleOutlined />
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col sm={12}>
                    <Row align="middle" gutter={[8, 8]}>
                        <div className="divimg">
                            <PinchZoomPan name="imagemzoom" initalScale={0} maxScale={15}>
                                <img src={`${urlApi}CatGrupo/GetImgGrupo?ctd003=${dadosCatalogo.codCatalogo}&ctd001=${grupoAtual.codGrupo}`} />
                            </PinchZoomPan>
                        </div>
                    </Row>
                </Col>
            </Row>
            <ModalDadosPecas exibirModal={openModal} fecharModal={() => setOpenModal(false)} codigoPeca={pecaSelecionada} />
            <DrawerAmpliaImagem caminhoimagem={imagemAmpliada.url} titulo={imagemAmpliada.titulo} exibirDrawerImg={openImg} fecharDrawerImg={onCloseDrawerImg} tipoImagem={0}></DrawerAmpliaImagem>
        </Drawer>
    )
}