import React,{ useState } from 'react';
import { Row,Button, Drawer} from "antd";
import { CloseOutlined } from "@ant-design/icons";

import "../../App.less";

export default function DrawerAmpliaImagem({caminhoimagem,titulo,exibirDrawerImg, fecharDrawerImg,tipoImagem}) {
    const [tamanho, setTamanho] = useState("");

    const imgAmpliada = {
        width: '800px',
        height: 'auto'
      };
      
      const imgNormal = {
        width: '400px',
        height: 'auto'
      };
    const onCloseImg = () => {
        fecharDrawerImg();
    };

    return (
        <Drawer title={titulo}
            width={tipoImagem == 1 ? "100%" : "50%"}
            visible={exibirDrawerImg}
            onClose={onCloseImg}
            closable={false}
            extra={
                    <Button onClick={onCloseImg} icon={<CloseOutlined />} size="small" htmlType="button" className="m-r-8">
                        Voltar
                    </Button>
              }
            footer={
                <div className="right">
                    <Button onClick={onCloseImg} icon={<CloseOutlined />} size="small" htmlType="button" className="m-r-8">
                        Voltar
                    </Button>
                </div>
            }> 
            <Row Row align="top" gutter={[8, 8]}>
                <div alignitems='center' >
                    <h3><img style={tipoImagem == 1 ? imgAmpliada : imgNormal} src={`${caminhoimagem}`}/></h3>
                </div>
            </Row>          
        </Drawer>      
    )
}