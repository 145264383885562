import React, { useState,useEffect } from 'react';
import { useHistory,Redirect } from "react-router-dom";
import { Layout, Row, Col, Typography, Form, Input, Checkbox, Button, notification,Select } from 'antd';
import { UnlockFilled, MailOutlined, SmileOutlined, SendOutlined } from '@ant-design/icons';

import { EsqueciSenha, SelecoesEmpresa, SlideLogin } from "./pages";
import api from '../../services/api';
import { login, setUser, EMPRESA_KEY,USER_KEY, getEntidadeLogado, getUserPerm } from '../../services/auth';

import "./login.less";
import logo from '../../assets/logoGestor.png';
import { ModulosPage } from "../../components";

// export const setItens = empresa => {
//     localStorage.setItem(EMPRESA_KEY, empresa);
//   };

const FORMULARIO = {
    LOGIN: 1,
    SELECIONA_EMPRESA: 2,
    ESQUECI_SENHA: 3,
}

const { Content } = Layout;

export default function Login() {

    const { Text } = Typography;
    const [formularioExibir, setFormularioExibir] = useState(FORMULARIO.LOGIN);
    const [carregando, setCarregando] = useState(false);

    const [visualizaCli, setVisualizaCli] = useState(false);

    const onFinish = values => {   
        setCarregando(true);
        //values.usu_spassword = btoa(values.usu_spassword);
        values.emp_key = EMPRESA_KEY;
        values.UsuEmail  = USER_KEY;
        delete values.remember;

        api.post('Usuario/AuthCatalogo', values).then(res => {
            if (res.status === 200) {
                if (res.data?.statusCode)
                    notification.warning({ message: res.data.value.message, description: 'Verifique os dados informados e tente novamente.' });
                else {
                    const token = res.data.token.access_token;
                    login(token);
                    setUser(JSON.stringify(res.data));
                    console.log(res.data,JSON.stringify(res.data));
                    window.location = "/Home";
                }
            }
        }, err => {
            notification.error({ message: err.response || 'Falha ao efetuar o login', description: 'Verifique os dados informados e tente novamente.' });
        }).finally(() => setCarregando(false));
    };

    const onFinishFailed = errorInfo => {
        console.log('Erro ao efetuar o login:', errorInfo);
    };

    return (
        <Layout className="vh100">
            <Content>
                <Row type="flex" justify="center" className="vh100">
                    <Col span={13} className="vh100 fixed-left">
                        <SlideLogin />
                    </Col>
                    <Col span={11} className="vh100 bg-log fixed-right">
                        <Row type="flex" align="middle" justify="center" className="vh100">
                            <Col span={18}>
                                <div className="text-center">
                                    <img src={logo} width="200" alt="Gestor Web" />
                                </div>
                                <Text className="f-14">
                                    Identifique abaixo suas credenciais para realizar o acesso.
                                </Text>
                                
                                {formularioExibir === FORMULARIO.LOGIN &&
                                    <div className="m-t-15">
                                        <Form name="login" initialValues={{ remember: true }} size="middle" onFinish={onFinish} onFinishFailed={onFinishFailed}>
                                            <Row align="middle" gutter={[0, 16]}>
                                            <Col span={24}>
                                                <Form.Item name="usu_semail" rules={[{ required: true, message: 'Informe seu e-mail para realizar o login.' }]}>
                                                    <Input placeholder="Informe seu e-mail" prefix={<MailOutlined />} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item name="usu_spassword" rules={[{ required: true, message: 'Informe sua senha de acesso.' }]}>
                                                    <Input.Password placeholder="Informe sua senha" prefix={<UnlockFilled />} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <div className="flexContent justifyContentBetween">
                                                    <Form.Item name="remember">
                                                        <Checkbox>Salvar meus dados de login</Checkbox>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <Button type="primary" htmlType="submit" loading={carregando} >
                                                                Acessar <SendOutlined />
                                                            </Button>
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col span={24}>
                                                <div className="flexContent justifyContentCenter">
                                                <Form.Item name="admin" >
                                                        
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <Button type="primary" htmlType="button" onClick={() => setFormularioExibir(FORMULARIO.ESQUECI_SENHA)} size="small">
                                                            Esqueci minha senha <UnlockFilled />
                                                        </Button>
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                        </Row>
                                            
                                        </Form>
                                    </div>
                                }
                                {formularioExibir === FORMULARIO.ESQUECI_SENHA && <EsqueciSenha onAcessar={() => setFormularioExibir(FORMULARIO.LOGIN)} />}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row type="flex" justify="center" className="vh100 hide">
                    <Col span={16} className="vh100">
                        <ModulosPage />
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
}