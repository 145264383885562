import React, { useEffect, useState,useRef } from "react";
import { Row, Col, Form, Tooltip, Input, Table, Tag, Carousel, Radio,Button,Modal,Card,Select } from 'antd';
import { QuestionCircleOutlined,RightCircleOutlined,FileImageOutlined,GroupOutlined } from '@ant-design/icons';

import "../../App.less";
import api from '../../services/api';
import DrawerGrupo from './listaGrupoSubGrupo';

export default function ConsultaCatalogo() {

    const [carregando, setCarregando] = useState(false);
    const [listaCatalogo, setListaCatalogo] = useState([]);
    const [listaLinhas, setListaLinhas] = useState([]);
    const [listaLinhaSelecionada, setListaLinhaSelecionada] = useState("");
    const [pesquisa, setPesquisa] = useState("");
    const [codCatalago,setCodCatalago] = useState("");
    const [nomeCatalago,setNomeCatalago] = useState("");
    const [codLinha,setCodLinha] = useState("");
    const [nomeLinha,setNomeLinha] = useState("");
    const [openGrupo, setOpenGrupo] = useState(false);
    const [loadPagina, setLoadPagina] = useState(0);
    const [numPage, setNumPage] = useState(1);
    const [numRegister, setNumRegister] = useState(20);
    const [totalList, setTotalList] = useState(0);
    const [resolucao, setResolucao] = useState(0);
    const { Option, OptGroup } = Select;

    useEffect(() => setFocusToTable(), [listaCatalogo]);
    useEffect(() =>  setResolucao(window.innerHeight));

    function handleChange(value) {
        setListaLinhaSelecionada(value);
    }

    const showDrawer = async () => {
        setOpenGrupo(true);
    }

    const closeDrawer = () => {
        setOpenGrupo(false);
    }

    useEffect(() => carregaCatalogos(),[numRegister, numPage,listaLinhaSelecionada,pesquisa]);

    useEffect(() => {
        setLoadPagina(loadPagina+1);
        api.get(`CatLinha/GetAll`).then(res => {
            if (res.status === 200) {
                setListaLinhas(res.data.map((x) => ({ key: x.cte001, ...x })));             
            }
        }, err => {
            console.log(err.response)
        }).finally();
    }, []);

    function setFocusToTable(){
        if(listaCatalogo.length >0 && document.forms[0].elements.length > 3)
        {
            document.forms[0].elements[3].focus();
        }
        if(loadPagina == 1)
        {
            document.forms[0].elements[0].focus();
        }
    }

    function carregaCatalogos()
    {
        api.get(`CatCatalogo/GetAllFilter?linhas=${listaLinhaSelecionada}&filtro=${pesquisa}`).then(res => {
            if (res.status === 200) {
                setListaCatalogo(res.data.data.map((x) => ({ key: x.cta001, ...x })));                
            }
        }, err => {
            console.log(err.response)
        }).finally();
    }
     
return (
<div className="p-10">
            <Row align="middle" gutter={[8, 8]}>
                <h2 nameClass="title-dashboard">Consulta por Catálogo</h2>
           </Row>
            <Form layout="vertical">
            <Row align="middle" gutter={[8, 8]}>
                    <Col sm={12}>
                        <Form.Item>
                            <div>
                                <label> Pesquisar por </label>
                                <Tooltip title="Código,Descrição">
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            </div>
                            <Input.Search placeholder="Catálogo"  enterButton allowClear
                                onSearch={(value,e) => {setPesquisa(value);
                                }}
                                onPressEnter={e => {
                                    setPesquisa(e.target.value); 
                                }}
                                onKeyPress={e => {
                                    if(e.charCode === 13)
                                    {
                                        setPesquisa(e.target.value); 
                                    }
                                }}
                                />
                        </Form.Item>
                    </Col>
                    <Col sm={12}>
                    <Form.Item>
                            <div>
                                <label> Selecione uma Linha </label>
                                <Tooltip title="Código,Descrição">
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            </div>
                                <Select showSearch mode="multiple" onChange={handleChange} options={listaLinhas.map((item) => {
                                    return { label: item.cte002, value: item.cte001, key: item.cte001 }
                                })} filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0} placeholder="Selecione uma Linha..." />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align="top" gutter={[8, 8]}>
                    <Col sm={24} align="top">
                        <div className="tabela hgtCatalogo">
                            <Table id="TabelaCatalogo" small responsive columns={
                                [
                                    {
                                        title: 'Catálogo',
                                        fixed: 'left',
                                        render: ({ cta001 }) => (
                                            <div>
                                                <b>{cta001}</b>
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Descrição',
                                        fixed: 'left',
                                        render: ({ cta002 }) => (
                                            <div>
                                                <b>{cta002}</b>
                                            </div>
                                        ),
                                    },
                                    {
                                        title: '',
                                        align: 'left',
                                        width: 130,
                                        render: ({ cta001,cta002,cte001,cte002}) => (
                                            <div>
                                                        <b><a onClick={() => {
                                                            setCodCatalago(cta001);
                                                            setNomeCatalago(cta002);
                                                            setCodLinha(cte001);
                                                            setNomeLinha(cte002);
                                                            showDrawer();
                                                        }}  >Consulta Grupos <GroupOutlined /></a></b>
                                            </div>
                                        ),
                                    },
                                ]} pagination={{
                                    current: numPage,
                                    pageSize: numRegister,
                                    total: totalList,
                                    onChange: (pg) => setNumPage(pg),
                                    onShowSizeChange: (current, page) => setNumRegister(page)
                                }}
                                dataSource={listaCatalogo}
                                scroll={{ y: resolucao }}
                            />
                        </div>
                    </Col>
                </Row>
            </Form>
            <DrawerGrupo codCatalago={codCatalago} codLinha={codLinha} exibirDrawer={openGrupo} fecharDrawer={closeDrawer} nomeCatalogo={nomeCatalago} nomeLinha={nomeLinha}></DrawerGrupo>
        </div>
    )
}