import React, { useEffect, useState } from "react";
import { Row, Col, Tooltip, Form, Input, Table, Tag, Button, Popover } from "antd";
import {
    QuestionCircleOutlined, EditOutlined, PlusOutlined,
} from "@ant-design/icons";
import ManutencaoMensagem from './manutencao';
import "../../App.less";
import api from '../../services/api';
import moment from 'moment';

export default function MensagensSends() {

    const [laod, setLoad] = useState(false);
    const [openManutencao, setOpenManutencao] = useState(false);
    const [updateList, setUpdateList] = useState(false);

    const [numPage, setNumPage] = useState(1);
    const [numRegister, setNumRegister] = useState(10);

    const [pesquisaCliente, setPesqueisaCliente] = useState("");
    const [listMensagens, setlistMensagens] = useState([]);
    const [dataRegister, setDataRegister] = useState({});
    const [filter, setFilter] = useState("");
    const [order, setOrder] = useState(`CtmDataCadastro`);


    useEffect(() => loadMensagens(), [numRegister, numPage, pesquisaCliente, filter, order, updateList]);

    function loadMensagens() {
        setLoad(true);
        api.get(`MensagensClientes/GetAll?numPage=${numPage}&numRegister=${numRegister}&filter=${filter}&order=${order}`).then(res => {
            if (res.status === 200) {
                setlistMensagens(res.data);
            }
        }, err => {
            console.log(err.response)
        }).finally(() => setLoad(false));
    }

    const showDrawer = async (item) => {
        if (item.ctmId > 0) {
            let response = await api.get(`MensagensClientes/GetAll?numPage=1&numRegister=1&ctmId=${item.ctmId}&order=${order}`);
            let listCliens = await api.get(`MensagensClientes/GetClientByControl?ctmId=${item.ctmId}`);
            let data = {
                msmId: response.data.data[0].msm.msmId,
                msmTitulo: response.data.data[0].msm.msmTitulo,
                msmDescricao: response.data.data[0].msm.msmDescricao,
                ctmId: response.data.data[0].ctmId,
                clientes: listCliens.data
            }
            setDataRegister(data);
        } else setDataRegister({ ctmId: 0 });

        setOpenManutencao(true);
    }

    const closeDrawer = () => {
        setUpdateList(true);
        setOpenManutencao(false);
    }

    return (
        <div className="p-10">
            <Row align="middle" justify="space-between" gutter={[8, 8]}>
                <Col span={20}>
                    <span>
                        <h2 nameClass="title-dashboard">Mensagens Enviadas</h2>
                    </span>
                </Col>
                <Col span={4}>
                    <Button type="primary" className="right" icon={<PlusOutlined />} size="middle" onClick={() => showDrawer({ ctmId: 0 })}>
                        NOVO
                    </Button>
                </Col>
            </Row>
            <div>
                <Form layout="vertical">
                    <Row align="middle" gutter={[8, 8]}>
                        <Col sm={24}>
                            <Form.Item>
                                <div>
                                    <label> Pesquisar por </label>
                                    <Tooltip title="Código, Título, Descrição...">
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                </div>
                                <Input.Search allowClear="true" placeholder="Código, Título, Descrição..."
                                    value={filter} onChange={e => setFilter(e.target.value)}
                                    enterButton />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div>

                <div className="tabela">
                    <Table scroll={{ x: 1000 }} columns={
                        [
                            {
                                title: 'Código',
                                width: 105,
                                align: 'center',
                                fixed: 'left',
                                render: ({ ctmId, ctmAtivo }) => (
                                    <Popover content={ctmAtivo ? 'Ativo' : 'Inativo'} placement="right">
                                        <Tag color={'processing'}>
                                            <b>#{ctmId}</b>
                                        </Tag>
                                    </Popover>
                                ),
                            },
                            {
                                title: 'Título',
                                fixed: 'left',
                                render: ({ msm: { msmTitulo } }) => (
                                    <div>
                                        <b>{msmTitulo}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Descrição',
                                fixed: 'left',
                                render: ({ msm: { msmDescricao } }) => (
                                    <div>
                                        <span>{msmDescricao}</span>
                                    </div>
                                ),
                            },
                            {
                                title: 'Data de Envio',
                                fixed: 'left',
                                render: ({ ctmDataCadastro }) => (
                                    <div>
                                        <span>{moment(ctmDataCadastro).format('DD-MM-YYYY HH:MM')}</span>
                                    </div>
                                ),
                            },
                            {
                                title: 'Operações',
                                dataIndex: '',
                                align: 'center',
                                fixed: 'right',
                                key: 'x',
                                width: 85,
                                render: (data) => (
                                    <div>
                                        <Row align="middle" justify="center" gutter={[5, 0]}>
                                            <Col>
                                                <Button onClick={() => showDrawer(data)} icon={<EditOutlined />} />
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            },
                        ]}
                        pagination={{
                            current: numPage,
                            pageSize: numRegister,
                            total: listMensagens.total,
                            onChange: (pg) => setNumPage(pg),
                            onShowSizeChange: (current, page) => setNumRegister(page)
                        }}
                        dataSource={listMensagens.data}
                    />
                </div>
            </div>
            <ManutencaoMensagem dataRegister={dataRegister} exibirDrawer={openManutencao} fecharDrawer={closeDrawer}></ManutencaoMensagem>
        </div>
    );
}