import React, { useEffect, useState } from "react";
import { Row, Col, Form, Select, notification } from "antd";
import Modal from "antd/lib/modal/Modal";

import "../../App.less";
import formatNumber from "../../ValueObjects/formatNumber";
import { getClienteSelecionado, getUserPerm } from "../../services/auth";
import api from "../../services/api";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";

export default function ModaDadosPecas({ exibirModal, fecharModal, codigoPeca }) {
    const [dadosPeca, setDadosPeca] = useState({});
    const [dadosTabela, setDadosTabela] = useState([]);
    const onCloseModal = () => {
        fecharModal()
    };
    useEffect(() => {
        abrirModalPecas()
    }, [codigoPeca]);

    function createData(name, value, style) {
        return { name, value, style }
    }

    function abrirModalPecas() {
        let url = "";
        if (!!!codigoPeca) {
            fecharModal();
            return;
        }
        let clienteSelecionado = getClienteSelecionado();
        let dadosUser = getUserPerm();
        if (dadosUser.cli_id > 0) {
            url = `CatPecas/CalculoST_Item?codPeca=${codigoPeca}&codeVend=0&codeCli=${dadosUser.cli_id}`;
        } else if (clienteSelecionado > 0) {
            url = `CatPecas/CalculoST_Item?codPeca=${codigoPeca}&codeVend=${dadosUser.ven_id}&codeCli=${clienteSelecionado}`;
        } else {
            notification.warn({ description: "Favor selecionar um cliente!", message: "Cliente não selecionado!" });
            fecharModal();
            return;
        }

        api.get(url).then(
            res => {
                if (!!res.data?.result) {
                    let dados = res.data.result;
                    let dadosPeca = {
                        codigoPeca: dados.ctg001,
                        codigoAlternativo: dados.ctg008,
                        descricaoPeca: dados.ctg002,
                        modeloInfAdic: dados.ctg009,
                        unidadeMedida: dados.ctg010,
                        qtde: dados.ctg012,
                        peso: dados.ctg011,
                        ncm: dados.ctg013,
                        precoLista: dados.ctg015,
                        desconto: dados.per_desc_cli,
                        precoLiquido: dados.valor_liquido,
                        perIpi: dados.per_ipi,
                        valIpi: dados.val_ipi,
                        percIcms: dados.perc_icms,
                        valIcms: dados.val_icms,
                        mva: dados.per_MVA,
                        valST: dados.val_st,
                        precoFinal: dados.preco_final,
                        estoque: dados.estoque,
                        infoLocal: dados.endereco_cli,
                        cidade: dados.cidade_cli,
                        uf: dados.uf_cli,
                        cep: dados.cep_cli,
                        baseCalcSt: dados.baseCalcSt,
                        percRedBaseCalcSt: dados.percRedBaseCalcSt,
                    };

                    let listaDadosTab = [
                        createData("Preço Lista:", formatNumber(!!dados.ctg015 ? dados.ctg015 : 0, true), ""),
                        createData("(%) Desconto:", formatNumber(!!dadosPeca.desconto ? dadosPeca.desconto : 0, false), ""),
                        createData("Preço Líquido: ", formatNumber(!!dadosPeca.precoLiquido ? dadosPeca.precoLiquido : 0, true), ""),
                        createData("(%) IPI: ", formatNumber(!!dadosPeca.perIpi ? dadosPeca.perIpi : 0, false) + " (" + formatNumber(!!dadosPeca.valIpi ? dadosPeca.valIpi : 0, true) + ")", ""),
                        createData("% MVA: ", formatNumber(!!dadosPeca.mva ? dadosPeca.mva : 0, false), ""),
                        createData("% ICMS: ", formatNumber(!!dadosPeca.percIcms ? dadosPeca.percIcms : 0, false) + " (" + formatNumber(!!dadosPeca.valIcms ? dadosPeca.valIcms : 0, true) + ")", ""),
                        createData("% Red Base de Calculo ICMS: ", formatNumber(!!dados.percRedBaseCalcSt ? dados.percRedBaseCalcSt : 0, false), ""),
                        createData("Base Cálculo ICMS ST: ", formatNumber(!!dadosPeca.baseCalcSt ? dadosPeca.baseCalcSt : 0, false), ""),
                        createData("Valor do ICMS ST: ", formatNumber(!!dadosPeca.valST ? dadosPeca.valST : 0, true), ""),
                        createData("Preço Final: ", formatNumber(!!dadosPeca.precoFinal ? dadosPeca.precoFinal : 0, true), ""),
                        createData("Estoque: ", dadosPeca.estoque > 0 ? "ITEM DISPONÍVEL" : "ITEM EM PRODUÇÃO", dadosPeca.estoque > 0 ? "txtEstoqueAzul" : "txtEstoqueVermelho")
                    ];
                    setDadosTabela(listaDadosTab);
                    setDadosPeca(dadosPeca);
                }
            }
        ).catch(
            err => {
                console.log(err);
            }
        );

    };

    return (
        <Modal
            title="Informações da Peça"
            visible={exibirModal}
            onOk={onCloseModal}
            onCancel={onCloseModal}
            style={{ top: 10 }}
            width={600}>
            <div className="dvBordaFim">
                <p>Código da peça: <b>{dadosPeca.codigoPeca}</b></p>
                <p>Código Alternativo: <b>{dadosPeca.codigoAlternativo}</b></p>
                <p>Descrição: <b>{dadosPeca.descricaoPeca}</b></p>
                <p>Modelos/Inf Adicionais: <b>{dadosPeca.modeloInfAdic}</b></p>
            </div>
            <div style={{ lineHeight: "5px", marginBottom: "10px" }}>
                <p>Unidade de Medida: <b>{dadosPeca.unidadeMedida}</b></p>
                <p>Qtde da Embalagem: <b>{dadosPeca.qtde}</b></p>
                <p>Peso: <b>{formatNumber(!!dadosPeca.peso ? dadosPeca.peso : 0, false)}</b></p>
                <p>NCM: <b>{dadosPeca.ncm}</b></p>
            </div>
            <Table size="small">
                <TableBody>
                    {dadosTabela.map((row) => (
                        <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            style={{ margin: 0 }}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell className={row.style}>{row.value}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>

            </Table>
            <div className="dvFimModal">
                <p>Informações Cliente: <b>{dadosPeca.infoLocal}</b></p>
                <p>Cidade: <b>{dadosPeca.cidade}</b></p>
                <p>CEP: <b>{dadosPeca.cep}</b>  UF: <b>{dadosPeca.uf}</b></p>
            </div>

        </Modal>)
}