import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Form, Tooltip, Input, Table, Carousel, Modal, notification, Button, Collapse } from 'antd';
import { QuestionCircleOutlined, InfoCircleOutlined, FileSearchOutlined, ExclamationCircleOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

import "../../App.less";
import api from '../../services/api';
import { urlApi, getClienteSelecionado, getUserPerm } from '../../services/auth';
import formatNumber from '../../ValueObjects/formatNumber';
import { ModalDadosPecas } from "../../components/modals";
import DrawerGrupo from "./drawerGrupo";

export default function ConsultaPecas() {

    const [exibePeca, setExibePeca] = useState(false);
    const [exibeGrupo, setExibeGrupo] = useState(false);
    const [retornoPecas, setRetornoPecas] = useState({});
    const [listaGrupo, setListaGrupo] = useState([]);
    const [listaFotos, setListaFotos] = useState([]);
    const [dadosPaginacao, setDadosPagincao] = useState({ numRegister: 10, numPage: 1 });
    const [selectedRowKeys, setSelectedRowKeys] = useState([0]);
    const [openModal, setOpenModal] = useState(false);
    const [pecaSelecionada, setPecaSelecionada] = useState(null);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [dadosCatalogo, setDadosCatalogo] = useState({});
    const [filtrosAvanc, setFiltrosAvanc] = useState(false);
    const [formulario] = Form.useForm();
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        type: 'radio',
        columnWidth: 18,
    };

    const toogleFiltro = rec => {
        setFiltrosAvanc(!filtrosAvanc);
    }

    useEffect(() => {
        if (!!retornoPecas) {
            setExibePeca(retornoPecas.data?.length > 0)
        }
    }, [retornoPecas]);

    useEffect(() => {
        if (!!listaGrupo) {
            setExibeGrupo(listaGrupo.length > 0);
        }
    }, [listaGrupo]);

    function onSelectChange(selectedRowKeys, selectedRows) {
        setSelectedRowKeys(selectedRowKeys);
        setListaGrupo(selectedRows[0].cat004.map((x) => ({ key: x.ctd_id, ...x })));
        setListaFotos(selectedRows[0].cat003);
        /*
        
        setCodPeca(selectedRows[0].ctg001);
        setCodPecaId(selectedRows[0].ctg_id);
        salvarHistoricoPeca(selectedRows[0].ctg002,selectedRows[0].ctg_id);*/
    };

    function buscarDadosPeca(valor) {
        if (!!valor) {
            let filtros = `&Codigo=${valor}`;
            filtrar(filtros);
        }
    };

    function filtragemAdicional() {
        let filtros = "";
        if (!!formulario.getFieldValue().filDescricao) {
            filtros += `&Descricao=${formulario.getFieldValue().filDescricao}`;
        }
        if (!!formulario.getFieldValue().filLinha) {
            filtros += `&CodLinha=${formulario.getFieldValue().filLinha}`;
        }
        if (!!formulario.getFieldValue().filAplicacao) {
            filtros += `&Aplicacao=${formulario.getFieldValue().filAplicacao}`;
        }

        filtrar(filtros);
    }

    function filtrar(filtros) {
        api.get(`CatPecas/GetAllFilters?numRegister=${dadosPaginacao.numRegister}&numPage=${dadosPaginacao.numPage}${filtros}`).then(
            res => {
                if (!!res.data) {
                    let dados = { ...res.data }
                    debugger;
                    if (dados.data.length > 0) {
                        dados.data = dados.data.map((x) => ({ key: x.ctg_id, ...x }));
                        setSelectedRowKeys([dados.data[0].ctg_id]);
                        let listaGrp = dados.data[0].cat004;
                        if (!!listaGrp && listaGrp.length > 0) {
                            setListaGrupo(listaGrp.map((x) => ({ key: x.ctd_id, ...x })));
                        }
                        setListaFotos(dados.data[0].cat003);
                        setRetornoPecas(dados);
                    } else {
                        notification.warning({ description: "Não há resultados para sua busca", message: "Aviso" });
                        return;
                    }
                }
            }
        ).catch(
            error => {
                console.log(error);
            }
        );
    }

    return (
        <div className="p-10">
            <Row align="middle" gutter={[8, 8]}>
                <h2 nameClass="title-dashboard">Consulta Peças</h2>
            </Row>
            <Form layout="vertical" form={formulario}>
                <Row align="middle" gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={14} xxl={16}>
                        <Form.Item name="pesquisaPeca" label={
                            <div>
                                Pesquisar por
                                <Tooltip title="Código da Peça">
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            </div>
                        }>
                            <Input.Search placeholder="Código" allowClear onSearch={(valor) => {
                                buscarDadosPeca(valor);
                            }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align="middle" gutter={[0, 8]} className="m-t-10">
                    <Col span={24}>
                        <Collapse className="p-relative mt-coll " accordion ghost destroyInactivePanel={true}>
                            <Collapse.Panel key="1" showArrow={false} extra={
                                <Button type="primary" onClick={rec => {
                                    setFiltrosAvanc(!filtrosAvanc);
                                }} block>
                                    {filtrosAvanc ? <ArrowUpOutlined /> : <ArrowDownOutlined />} Realizar pesquisa avançada
                                </Button>
                            }>
                                <Row gutter={[8, 8]} >
                                    <Col xs={24} sm={24} md={24} lg={24} xl={18} xxl={19}>
                                        <Row justify="start" gutter={[8, 0]} style={{ verticalAlign: "middle" }}>
                                            <Col span={5}>
                                                <Form.Item label="Cód. Linha" name="filLinha">
                                                    <Input placeholder="Informe o código da linha" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={5}>
                                                <Form.Item label="Descrição" name="filDescricao">
                                                    <Input placeholder="Informe a descrição da peça" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={5}>
                                                <Form.Item label="Aplicação" name="filAplicacao">
                                                    <Input placeholder="Informe a aplicação da peça" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={5}>
                                                <Button type="primary" className="t-mob-573" align onClick={() => { filtragemAdicional() }}>Pesquisar</Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Collapse.Panel>
                        </Collapse>

                    </Col>
                </Row>
                {exibePeca &&
                    <Row align="top" gutter={[8, 8]} style={{ marginTop: '10px' }}>
                        <Col sm={18} align="top">
                            <div className="tabela">
                                <Table id="TabelaPecas" small scroll={{ x: 500 }} columns={
                                    [
                                        {
                                            title: 'Código Peça',
                                            width: 120,
                                            fixed: 'left',
                                            render: ({ ctg001 }) => (
                                                <div>
                                                    <b>{ctg001}</b>
                                                </div>
                                            ),
                                        },
                                        {
                                            title: 'Descrição',
                                            fixed: 'left',
                                            render: ({ ctg002 }) => (
                                                <div>
                                                    <b>{ctg002}</b>
                                                </div>
                                            ),
                                        },
                                        {
                                            title: 'Linha',
                                            align: 'left',
                                            width: 40,
                                            render: ({ ctg005 }) => (
                                                <div>
                                                    <b>{ctg005}</b>
                                                </div>
                                            ),
                                        },
                                        {
                                            title: 'Descrição Linha',
                                            align: 'left',
                                            width: 140,
                                            render: ({ cte002 }) => (
                                                <div>
                                                    <b>{cte002}</b>
                                                </div>
                                            ),
                                        },
                                        {
                                            title: 'Proj',
                                            fixed: 'left',
                                            width: 40,
                                            render: ({ ctg003 }) => (
                                                <div>
                                                    <b>{ctg003}</b>
                                                </div>
                                            ),

                                        },
                                        {
                                            title: 'Fabr',
                                            fixed: 'left',
                                            width: 40,
                                            render: ({ ctg004 }) => (
                                                <div>
                                                    <b>{ctg004}</b>
                                                </div>
                                            ),
                                        },
                                        {
                                            title: '',
                                            fixed: 'left',
                                            width: 20,
                                            render: ({ ctg001 }) => (
                                                <div>
                                                    <a onClick={() => {
                                                        setPecaSelecionada(ctg001);
                                                        setOpenModal(true);
                                                    }}><InfoCircleOutlined /></a>
                                                </div>
                                            ),
                                        },
                                    ]}
                                    dataSource={retornoPecas?.data}
                                    rowSelection={rowSelection}
                                    rowClassName={(record, index) => record.ctg004 === 1 ? 'green' : record.ctg003 === 1 ? 'yellow' : ''}
                                    locale={{
                                        emptyText: (
                                            <Row>
                                                <b><ExclamationCircleOutlined /> PEÇA NÃO ENCONTRADA</b>
                                            </Row>)
                                    }}
                                />
                            </div>
                        </Col>
                        <Col sm={6}>
                            <Carousel>
                                {listaFotos.map((x) =>
                                    <div alignitems='center' key={x.ctc_id}>
                                        <h3 className="h3-carousel">
                                            {<img className="img-carousel" src={`${urlApi}CatPecas/GetImgPeca?ctc001=${x.ctc001}&ctc002=${x.ctc002}`} />
                                            }</h3>
                                    </div>
                                )}
                            </Carousel>
                            <div align="top" class="green"><b>* Peças já Fabricadas</b></div>
                            <div align="top" class="yellow"><b>* Peças em Desenvolvimento</b></div>
                        </Col>
                    </Row>

                }
                {exibeGrupo &&
                    <Row align="top" gutter={[8, 8]} >
                        <Col sm={18}>

                            <div className="tabela hgtGrupos">
                                <Table sticky small columns={
                                    [
                                        {
                                            title: 'Grupo',
                                            width: 80,
                                            fixed: 'left',
                                            render: ({ ctd001 }) => (
                                                <div>
                                                    <b>{ctd001}</b>
                                                </div>
                                            ),
                                        },
                                        {
                                            title: 'Descrição',
                                            fixed: 'left',
                                            render: ({ ctd002 }) => (
                                                <div>
                                                    <b>{ctd002}</b>
                                                </div>
                                            ),
                                        },
                                        {
                                            title: 'Catálogo',
                                            fixed: 'left',
                                            width: 100,
                                            render: ({ ctd003 }) => (
                                                <div>
                                                    <b>{ctd003}</b>
                                                </div>
                                            ),
                                        },
                                        {
                                            title: 'Descrição Catálogo',
                                            fixed: 'left',
                                            ellipsis: {
                                                showTitle: false,
                                            },
                                            render: ({ cta002 }) => (
                                                <Tooltip placement="topLeft" title={cta002}>
                                                    <b>{cta002}</b>
                                                </Tooltip>
                                            ),
                                        },
                                        {
                                            title: '',
                                            align: 'left',
                                            width: 130,
                                            render: ({ ctd003, ctd001, ctd002, cta002, ctd_id, ctb003 }) => (
                                                <div>
                                                    <b><a onClick={() => {
                                                        let catalogo = {
                                                            codCatalogo: ctd003,
                                                            codGrupo: ctd001,
                                                            nomeGrupo: ctd002,
                                                            nomeCatalogo: ctd003 + " - " + cta002,
                                                            codPeca: ctb003
                                                        };
                                                        setDadosCatalogo(catalogo);
                                                        setOpenDrawer(true);
                                                    }}>Consultar Peças <FileSearchOutlined /></a></b>

                                                </div>
                                            ),
                                        },
                                    ]}
                                    dataSource={listaGrupo}
                                    //scroll={{ y: resolucao }}
                                    pagination={false}

                                />
                            </div>
                        </Col>
                    </Row>
                }

            </Form>
            <ModalDadosPecas exibirModal={openModal} fecharModal={() => setOpenModal(false)} codigoPeca={pecaSelecionada} />
            <DrawerGrupo exibirDrawer={openDrawer} dadosCatalogoIdx={dadosCatalogo} onCloseDrawer={() => setOpenDrawer(false)} />
        </div>
    );

}