import React,{useState,useEffect}  from "react";
import { Row, Col, Card, Statistic } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import api from '../../services/api';

import "../../App.less";
import { BreadcrumbPage } from "../../components";
import { getUserPerm } from '../../services/auth';
import logo from '../../assets/MannCapa.png';

export default function Dashboard() {
    const [nomeUsu, setNomeUsu] = useState(getUserPerm().usu_sname);
    const [nomeCliente, setNomeCliente] = useState("");
    
    useEffect(() => buscarNomeCliente(),[nomeUsu]);
    
    function buscarNomeCliente(){
        api.get(`Clientes/GetToUser`).then(res => {
            if (res.status === 200) {
                setNomeCliente(res.data.cliNome);
            }
        }, err => {
            console.log(err.response);
        }).finally();
    }

    return (
        <div className="bgImg" style={{backgroundImage: `url(${logo})`}} >
            <Row align="middle" gutter={[8, 8]}>
                <Col span={24}>
                <span>
                    {nomeCliente.trimEnd()!=nomeUsu.trimEnd()?
                        <h2 nameClass="title-dashboard">Bem vindo {nomeCliente} - {nomeUsu}!</h2>
                        :<h2 nameClass="title-dashboard">Bem vindo {nomeCliente}!</h2>
                    }
                    </span>
                </Col>
            </Row>
            <div >
                <Row gutter={[8, 8]}>
                    <Col span={24}>
                        
                    </Col>
                </Row>
            </div>
        </div>
    );

}