import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Menu, Layout } from "antd";
import { HomeOutlined, UserOutlined, SendOutlined, MessageOutlined, ReadOutlined,SettingOutlined,FileSearchOutlined } from "@ant-design/icons";

import "./index.less";

import { getUserPerm } from '../../services/auth';


export default function MenuPage(incollapsed) {

    const [permissao, setPermissao] = useState(getUserPerm().perId);
    const [disableUsu, setDisableUsu] = useState(getUserPerm().perId === 3 ? false :  true);
    const [collapsed, setCollapsed] = useState(false);

    const [openKeys, setOpenKeys] = useState([]);
    const rootKeys = ["sub1", "sub2", "sub3", "sub4", "sub5", "sub6", "sub7", "sub8", "sub9", "sub10"];

    useEffect(() => {
        if (!incollapsed) {
            setOpenKeys(openKeys);
        }
    }, [openKeys, incollapsed]);

    const onOpenChange = (items) => {
        const latestOpenKey = items.find((key) => openKeys.indexOf(key) === -1);
        if (rootKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(items);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : openKeys);
        }
    };

    return (
        <Layout.Sider collapsible collapsed={collapsed} onCollapse={setCollapsed} className="menu-lateral">
            <Menu theme="light" mode="inline" defaultSelectedKeys={["0"]} onOpenChange={onOpenChange} openKeys={openKeys}>
                <Menu.Item>
                    <Link to="/">
                        <HomeOutlined />
                        <span>Home</span>
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="/consultapecas">
                        <FileSearchOutlined />
                        <span>Consulta Peças</span>
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="/consultacatalogo">
                        <ReadOutlined  />
                        <span>Consulta Catálogo</span>
                    </Link>
                </Menu.Item>
                {permissao==3&&
                    <Menu.Item>
                    <Link to="/consultaacessos">
                        <SettingOutlined />
                        <span>Consulta acessos</span>
                    </Link>
                    </Menu.Item>
                }
                
                {/* <Menu.Item>
                    <Link to="/fornecedor">
                        <MessageOutlined />
                        <span>Mensagens</span>
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="/sendSms">
                        <SendOutlined />
                        <span>Mensagens Enviadas</span>
                    </Link>
                </Menu.Item>              */}
            </Menu>
        </Layout.Sider>

    );

}