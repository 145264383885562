import axios from 'axios';
import { getToken, logout, getEmpresa, getLocal } from './auth';

//const api = axios.create({ baseURL: process.env.REACT_APP_PUBLIC_URL || 'http://192.168.11.146:5000/api/' });
//const api = axios.create({ baseURL: process.env.REACT_APP_PUBLIC_URL || 'http://localhost:5000/api/' });
const api = axios.create({ baseURL: process.env.REACT_APP_PUBLIC_URL || 'https://gestormobileapi.abase.com.br/api/' });

api.interceptors.request.use(async config => {
    const token = getToken();
    const empresa = getEmpresa();
    const local = getLocal();

    if (token) config.headers.Authorization = `Bearer ${token}`;
    if (empresa) config.headers.codigoempresa = empresa;
    if (local) config.headers.codigolocal = local;

    return config;
});

api.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if (error.response.status === 401) { 
        logout();
        window.location = '/';
    }
    return Promise.reject(error);
  });

export default api;